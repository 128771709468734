import React, {Component} from 'react';
import PropTypes from "prop-types";


class SingleAnswer extends Component {
	static propTypes = {
		answers: PropTypes.array.isRequired,
		onSelect: PropTypes.func.isRequired,
		toggleSignAnswers: PropTypes.func.isRequired,
		goBack: PropTypes.func.isRequired,
		currentNode: PropTypes.number
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		const {answers,currentNode} = this.props;
		return (
			<div>
				<div className="answers-list">
					{answers.map((e, index) => {
						return <div className="form-group customCheckbox " key={index}>
							<input type="radio" className="form-check-input" checked={false}
								   onClick={this.onHandleCheckbox(index)}/>
							<label className="form-check-label survey-answer" htmlFor="exampleCheck1" onClick={this.onHandleCheckbox(index)}>{e.text}</label>
						</div>
					})}
				</div>
				<div className="row">
					<div className="col-12 col-md-6">
						<div className="btns-container" >
							<div className="back">
								{currentNode>0 && <a className="" onClick={this.props.goBack}> {`<`} Indietro</a>}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	onHandleCheckbox = (index) => (e) => {
		const {answers} = this.props;
		this.props.toggleSignAnswers([answers[index].id]);
		this.props.onSelect(answers[index].nodes);
	};
}

export default SingleAnswer;
