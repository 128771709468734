import React, { useEffect } from "react";
import ReactGA from "react-ga";

ReactGA.initialize("UA-44803163-26");

export const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = page => {
		ReactGA.set({
			page,
			...options
		});
		if(process.env.REACT_APP_APP_ENVIRONMENT === 'production') {
			ReactGA.pageview(page);
		}
	};

	const HOC = props => {
		useEffect(() => trackPage(props.location.pathname), [
			props.location.pathname
		]);

		return <WrappedComponent {...props} />;
	};

	return HOC;
};
