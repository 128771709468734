import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import Header from "../../Components/Header/Header.component";
import Footer from "../../Components/Footer/Footer.component";
import classes from './ComeBack.module.scss';
import logo from "../../images/logo-comestai-150px-bianco.png";
import medico from '../../images/medicoBackHome.png'


class ComeBack extends Component {

	state = {};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		const {match: {params: {doctor}}} = this.props;
		const url = doctor ? `${doctor}` : '/';
		return (
			<>
				<div style={{minHeight: '90vh'}}>
					<section style={{backgroundColor: '#2092c1'}}>
						<div className={`container`}>
							<div className={`row align-items-center`}>
								<div className={`col-12 ${classes.content}`}>
									<div className={`row`}>
										<div
											className={`col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center`}>
											<Link to={url} className={`${classes.logoBig}`}>
												<img src={logo}/>
											</Link>
										</div>
										<div
											className={`col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pt-5 text-center`}>
											<h2 className={classes.title}>
												Grazie per averci fatto sapere come stai.
											</h2>
										</div>
										<div
											className={`col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 pb-4 text-center`}>
											<p className={`${classes.subtitle}`}>
												Stiamo recapitando il tuo questionario ad un medico che si occuperà di
												tenere sotto controllo il tuo stato di salute. Riceverai notifiche via
												email dal medico a te assegnato o, se necessario, potrai essere
												contattato telefonicamente.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section style={{margin: '50px 0'}}>
						<div className={`container`}>
							<div className="row align-items-center">
								<div className="col-sm-6">
									<img src={medico} style={{maxWidth: "100%"}}/>
								</div>
								<div className="col-sm-6">
									<h2 style={{"fontSize":"32px","fontWeight":"bold","color":"#3e515e"}}>Aiutaci ad aiutarti</h2>
									<p style={{"fontSize":"18px","fontWeight":"600","color":"#3e515e"}}>Più volte ricompilerai il questionario, più accurata sarà la valutazione ed
										il monitoraggio del tuo stato di salute.</p>
									<h2 style={{"fontSize":"24px","fontWeight":"bold","color":"#2092c1"}}>Ripeti il questionario anche domani</h2>
									<p style={{"fontSize":"18px","fontWeight":"600","color":"#3e515e"}}>e nei prossimi giorni in modo da poter monitorare costantemente lo sviluppo,
										positivo o negativo, dei tuoi sintomi.</p>
								</div>
							</div>
						</div>
					</section>
				</div>
				<Footer/>
			</>
		);
	}
}

export default withRouter(ComeBack);
