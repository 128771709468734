import React from 'react';
import Header from "../../Components/Header/Header.component";
import Footer from "../../Components/Footer/Footer.component";
import classes from './StaticPages.module.scss';

const PrivacyPage = () => <>
    <div className={`${classes.pageWrap}`}>
        <Header/>
        <div className={`${classes.main}`}>
            <div className={`container statics`}>
                <div className="row">
                    <div className="col-sm-12">
                        <h2 style={{textAlign: 'left'}} dir="ltr">
                            Informativa sul trattamento dei dati personali
                        </h2>
                        <br/>
                        <p dir="ltr">
                            La presente informativa le viene fornita in relazione al trattamento di
                            dati connessi all’utilizzo della piattaforma “Come stai”, di cui ha scelto
                            di servirsi per comunicare ad un medico curante alcune informazioni
                            relative al suo stato di salute (la “Piattaforma”).
                        </p>
                        <br/>
                        <p dir="ltr">
                            L’informativa è resa, ai sensi degli artt. 13 e 14 del Regolamento (UE)
                            2016/679 (il “Regolamento”), da Kromin S.R.L., con sede legale in Via
                            Cantinella, 28A - 03023 Ceccano (FR). Kromin S.R.L. le fornisce la presente
                            informativa per conto del medico curante (di seguito “Medico curante”) che
                            tratterà i suoi dati personali in qualità di titolare del trattamento al
                            fine di fornirle il supporto da Lei richiesto.
                        </p>
                        <br/>
                        <p dir="ltr">
                            Le vengono illustrate di seguito le finalità e le modalità con cui saranno
                            trattati i suoi dati personali nel caso in cui Lei invii una o più risposte
                            al questionario relativo al suo stato di salute attraverso la Piattaforma.
                        </p>
                        <br/>
                        <p dir="ltr">
                            <strong>Categorie di dati personali</strong>
                        </p>
                        <p dir="ltr">
                            Il Medico curante potrà trattare le seguenti categorie di dati personali a
                            Lei riferibili:
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr">
                                    dati anagrafici (nome, cognome, codice fiscale) e dati di recapito
                                    (indirizzo di abitazione, indirizzo di posta elettronica, recapito
                                    telefonico);
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    informazioni sul suo stato di salute che risultano in particolare
                                    dalle risposte da Lei fornite al questionario che le viene
                                    somministrato tramite la Piattaforma.
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr">
                            Il conferimento dei dati sopra indicati è necessario per consentire al
                            Medico curante di prestarle il necessario supporto; in caso di mancato
                            conferimento, il Medico curante potrebbe non essere in grado di assisterla.
                        </p>
                        <br/>
                        <p dir="ltr">
                            <strong> Finalità e base giuridica del trattamento </strong>
                        </p>
                        <p dir="ltr">
                            I dati personali di cui alla lettera (A) saranno trattati al fine di
                            consentirLe l'autovalutazione del proprio stato di saluto e di prestarLe
                            l’eventuale assistenza necessaria, ai sensi dell’art. 6, lett. B) del
                            Regolamento. I dati di cui alla lettera (B) saranno trattati per la
                            medesima finalità sulla base di un suo specifico consenso. Le ricordiamo
                            che in caso di mancato conferimento del consenso al trattamento dei dati,
                            il Medico curante non potrà prestarLe l’assistenza richiesta.
                        </p>
                        <br/>
                        <p dir="ltr">
                            <strong>Modalità del trattamento</strong>
                        </p>
                        <p dir="ltr">
                            I suoi dati personali saranno trattati con modalità sia manuale sia
                            informatizzata nel rispetto delle previsioni di cui al Regolamento, della
                            normativa italiana in materia trattamento dei dati sanitari, ivi inclusi
                            eventuali provvedimenti dell’Autorità garante per la protezione dei dati
                            personali.
                        </p>
                        <br/>
                        <p dir="ltr">
                            <strong> Durata del trattamento e conservazione dei dati personali </strong>
                        </p>
                        <p dir="ltr">
                            I dati personali di cui alla lettera (A) e (B) saranno trattati per il
                            tempo necessario ad erogarLe i servizi richiesti e saranno conservati, dal
                            titolare del trattamento, per un periodo di 10 anni esclusivamente per
                            l’adempimento di obblighi di legge e per l’eventuale tutela dei diritti del
                            Medico curante.
                        </p>
                        <p dir="ltr">
                            Kromin S.r.l. conserverà e tratterà i suddetti dati sulla Piattaforma, in
                            qualità di responsabile del trattamento, fino al massimo di 6 mesi dalla
                            cessazione dello stato di emergenza. Successivamente a questo periodo, i
                            dati verranno conservati da Kromin S.R.L. e messi a disposizione del
                            titolare, previa pseudonimizzazione o anonimizzazione, per un periodo
                            massimo di 3 anni e sempre per le finalità connesse alla Piattaforma. I
                            dati potranno essere gestiti attraverso l’utilizzo di sistemi in modalità
                            cloud per garantire al Cliente le migliori opportunità in termini di
                            elasticità e flessibilità del servizio, sicurezza fisica e resilienza dei
                            dati, disponibilità e ridondanza delle risorse. I dati personali sono
                            sottoposti alle misure di sicurezza previste dell’art. 32 del Regolamento
                            aggiornate e adeguate con regolarità. Kromin ha attivato, inoltre, sistemi
                            di protezione integrativa al fine di monitorare e migliorare il livello di
                            sicurezza e adeguatezza contro la perdita, gli usi illeciti, non corretti o
                            non autorizzati dei dati.
                        </p>
                        <br/>
                        <p dir="ltr">
                            <strong>Comunicazione e diffusione dei dati personali e categorie di soggetti terzi</strong>
                        </p>
                        <p dir="ltr">
                            L’utente è a conoscenza che i dati personali potranno essere comunicati
                            all’esterno a terzi che operano in qualità di titolari autonomi o di
                            responsabili esterni del trattamento debitamente nominati ai sensi della
                            normativa vigente cui la comunicazione sia necessaria per l’adempimento
                            degli obblighi di legge e per il perseguimento delle finalità sopra
                            descritte, nonché per attività comunque connesse all’utilizzo della
                            Piattaforma. Alcuni trattamenti di dati personali potranno essere
                            effettuati anche da soggetti terzi ai quali il Medico curante ha affidato
                            talune attività.
                        </p>
                        <p dir="ltr">
                            Questi soggetti, tra i quali rientra Kromin S.R.L., sono designati
                            Responsabili del trattamento (art. 28 del Regolamento) e hanno ricevuto
                            adeguate istruzioni operative, con particolare riferimento all’adozione
                            delle misure minime e idonee di sicurezza, al fine di poter garantire la
                            riservatezza e la sicurezza dei dati. Potrà richiedere un elenco di tali
                            soggetti responsabili del trattamento scrivendo a Kromin S.R.L. al seguente
                            indirizzo info@comestai.org. Kromin S.R.L. provvederà a inoltrare la sua
                            richiesta al Medico curante titolare del trattamento affinché questi possa
                            fornirLe riscontro.
                        </p>
                        <br/>
                        <p dir="ltr">
                            <strong>Diritti dell’interessato</strong>
                        </p>
                        <p dir="ltr">
                            La informiamo che, nella sua qualità di interessato può esercitare i
                            diritti di cui agli articoli da 15 a 23 del Regolamento, scrivendo a Kromin
                            S.R.L. al seguente indirizzo info@comestai.org . Kromin S.R.L. provvederà a
                            inoltrare la sua richiesta al Medico curante affinché questi possa fornirle
                            riscontro.
                        </p>
                        <br/>
                        <p dir="ltr">
                            In particolare, potrà:
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    chiedere e ottenere informazioni in merito all’esistenza di suoi
                                    dati presso la Società e merito ai trattamenti di dati personali
                                    posti in essere dalla Società, nonché ottenere l’accesso agli
                                    stessi;
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    chiedere ed ottenere la ricezione in un formato strutturato, di uso
                                    comune e leggibile da dispositivo automatico dei suoi dati che
                                    siano trattati con mezzi automatizzati; potrà richiedere, inoltre,
                                    il trasferimento dei dati ad altro titolare del trattamento;
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    chiedere e ottenere la modifica e/o correzione dei suoi dati se
                                    ritiene che siano inaccurati o incompleti;
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    chiedere e ottenere la cancellazione – e/o la limitazione del
                                    trattamento - dei suoi dati qualora si tratti di dati o
                                    informazioni non necessari – o non più necessari – per le finalità
                                    che precedono, quindi decorso il periodo di conservazione indicato
                                    al paragrafo che precede.
                                </p>
                            </li>
                        </ul>
                        <br/>
                        <p dir="ltr">
                            Le ricordiamo che può proporre un reclamo per il trattamento illecito dei
                            suoi dati personali rivolgendosi all’Autorità di controllo.
                        </p>
                        <div>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</>;

const PrivacyDoctorPage = () => <>
    <div className={`${classes.pageWrap}`}>
        <Header/>
        <div className={`${classes.main}`}>
            <div className={`container statics`}>
                <div className="row">
                    <div className="col-sm-12">
                        <PrivacyDoctorContent/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</>

const PrivacyDoctorContent = () => <div>
        <h2 style={{textAlign: 'left'}} dir="ltr">
            Informativa sul trattamento dei dati personali
        </h2>
        <p dir="ltr">
            La presente informativa relativa al trattamento dei dati personali è resa,
            ai sensi dell’art. 13 del Regolamento (UE) 2016/679 (di seguito
            “Regolamento”), da Kromin S.R.L., con sede legale in Ceccano, in Via
            Cantinella 28A, C.F. e P. IVA 02874880608 (di seguito “Kromin S.R.L.”), in
            qualità di Titolare del trattamento. Tale informativa è fornita allo scopo
            di illustrare le finalità e le modalità con cui Kromin S.R.L. raccoglie e
            tratta i suoi dati personali, quali categorie di dati sono oggetto di
            trattamento, quali sono i diritti degli interessati al trattamento e come
            possono essere esercitati.
        </p>
        <br/>
        <p dir="ltr">
            <strong>Categorie di dati personali oggetto di trattamento</strong>
        </p>
        <p dir="ltr">
            Kromin S.R.L. tratterà alcuni dati personali a Lei riferibili (di seguito
            anche “Dati”) ed in particolare dati anagrafici e identificativi (nome,
            cognome), indirizzo, indirizzo di posta elettronica, il numero del
            tesserino di appartenenza all’ordine professionale dei medici-chirurghi.
        </p>
        <br/>
        <p dir="ltr">
            Il conferimento dei dati sopra indicati è necessario per consentirle di
            accedere e utilizzare la piattaforma “Come Stai” offerta da Kromin S.R.L.
            (di seguito anche “Piattaforma”), e poter prestare la sua opera in qualità
            di Medico curante.
        </p>
        <br/>
        <p dir="ltr">
            <strong>Finalità e base giuridica del trattamento</strong>
        </p>
        <p dir="ltr">
            Il trattamento dei suoi dati personali è effettuato da Kromin S.R.L. per
            consentirle di accedere alla Piattaforma e fornire l’assistenza che le
            verrà richiesta dai pazienti, ai sensi dell’art. 6, par. 1, lett. b) del
            Regolamento.
        </p>
        <br/>
        <p dir="ltr">
            <strong> Periodo di conservazione dei dati </strong>
        </p>
        <p dir="ltr">
            I Dati sono trattati solo per il tempo strettamente indispensabile per le
            finalità sopra menzionate e, pertanto, per il tempo necessario a fornire le
            anamnesi domiciliari che le verranno richieste. Inoltre, i suoi Dati
            saranno conservati per un periodo successivo di 10 anni dalla sua
            prestazione, esclusivamente per l’adempimento di obblighi di legge nonché
            per l’eventuale tutela dei diritti di Kromin S.R.L. .
        </p>
        <br/>
        <p dir="ltr">
            <strong>Categorie di soggetti ai quali i dati personali possono essere comunicati e
                finalità della comunicazione</strong>
        </p>
        <p dir="ltr">
            Kromin S.R.L. potrebbe comunicare i dati da lei forniti ai pazienti che
            prenderà in carico; a titolo esemplificativo, potrà essere comunicato il
            numero del suo tesserino di appartenenza all’ordine dei medici-chirurghi.
            Non è prevista alcuna forma di diffusione dei suoi Dati a soggetti
            ulteriori e indeterminati.
        </p>
        <br/>
        <p dir="ltr">
            <strong>Diritti degli interessati</strong>
            <br/>
            La informiamo che, nella sua qualità di interessato può esercitare i
            diritti di cui agli articoli da 15 a 23 del Regolamento, scrivendo a Kromin
            S.R.L. al seguente indirizzo:    <a href="mailto:info@comestai.org">info@comestai.org</a> .
        </p>
        <p dir="ltr">
            In particolare, potrà:
        </p>
        <ul>
            <li dir="ltr">
                <p dir="ltr">
                    chiedere e ottenere informazioni in merito all’esistenza di suoi
                    dati presso la Società e merito ai trattamenti di dati personali
                    posti in essere dalla Società, nonché ottenere l’accesso agli
                    stessi;
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    chiedere ed ottenere la ricezione in un formato strutturato, di uso
                    comune e leggibile da dispositivo automatico dei suoi dati che
                    siano trattati con mezzi automatizzati; potrai richiedere, inoltre,
                    il trasferimento dei dati ad altro titolare del trattamento;
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    chiedere e ottenere la modifica e/o correzione dei suoi dati se
                    ritiene che siano inaccurati o incompleti;
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    chiedere e ottenere la cancellazione – e/o la limitazione del
                    trattamento - dei suoi dati qualora si tratti di dati o
                    informazioni non necessari – o non più necessari – per le finalità
                    che precedono, quindi decorso il periodo di conservazione indicato
                    al paragrafo che precede.
                </p>
            </li>
        </ul>
        <br/>
        <p dir="ltr">
            Le ricordiamo che può proporre che un reclamo per il trattamento illecito
            dei suoi dati personali rivolgendosi all’Autorità di controllo.
        </p>
        <div>
            <br/>
        </div>
    </div>

const TermsDoctorContent = () =>  <div className={classes.terms}>
    <h5 style={{textAlign: 'left'}} dir="ltr">
        <strong>Nomina a responsabile esterno del trattamento dei dati personali</strong>
    </h5>
    <p dir="ltr">
        Il soggetto che aderisce alla piattaforma &#8220;Come Stai&#8221; nella sua
        qualit&#224; di medico curante e di titolare del trattamento (di seguito
        per brevit&#224; anche &#8220;Medico curante o &#8220;Titolare&#8221;) e
        Kromin S.R.L., con sede legale in Ceccano, in Via Cantinella 28A, C.F. e P.
        IVA 02874880608 (di seguito per brevit&#224; anche &#8220;Kromin
        S.R.L.&#8221; o &#8220;Responsabile&#8221;) (di seguito &#8220;Parti&#8221;
        e singolarmente &#8220;Parte&#8221;).
    </p>
    <br/>
    <p dir="ltr">
        Premesso che:
    </p>
    <ul>
        <li dir="ltr">
            <p dir="ltr">
                Kromin S.R.L. mette a disposizione dei medici di base la
                piattaforma &#8220;Come Stai&#8221; (di seguito
                &#8220;Piattaforma&#8221;) per consentire agli stessi di poter
                fornire la propria opera di consulenza e assistenza, ai fini di una
                anamnesi domiciliare, ai pazienti che ne facciano richiesta sempre
                mediante la Piattaforma (di seguito &#8220;Servizi&#8221;);
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Il Titolare si &#232; iscritto alla Piattaforma e intende
                utilizzare la stessa per la fruizione dei Servizi;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                per la fornitura dei Servizi Kromin S.R.L. tratter&#224;, quindi,
                dati personali di cui il Medico curante &#232; titolare;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Il Titolare, prima di affidare a Kromin S.R.L. lo svolgimento dei
                Servizi, ha valutato che il Responsabile presenti garanzie
                sufficienti per mettere in atto misure tecniche e organizzative
                atte a garantire una adeguata tutela dei dati trattati e dei
                diritti degli interessati;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                con il presente accordo (di seguito &#8220;Accordo&#8221;) il
                Titolare intende nominare Kromin S.R.L. responsabile del
                trattamento dei dati personali di cui &#232; titolare.
            </p>
        </li>
    </ul>
    <br/>
    <p dir="ltr">
        Tutto ci&#242; premesso le Parti convengono e stipulano quanto segue.
    </p>
    <p dir="ltr">
        <strong>1. Definizioni</strong>
    </p>
    <p dir="ltr">
        Ai fini del presente atto di nomina valgono le seguenti definizioni:
    </p>
    <ul>
        <li dir="ltr">
            <p dir="ltr">
                per &#8220;Dati personali&#8221; si intendono tutte le informazioni
                relative ad una persona fisica, identificata o identificabile
                (l&#8217;&#8220;Interessato&#8221;) che il Responsabile del
                trattamento tratta per conto dei Titolari del trattamento allo
                scopo di fornire i Servizi;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                per &#8220;Garante&#8221; si intende il Garante per la protezione
                dei dati personali istituito dalla l. 31 dicembre 1996 n. 675;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                per &#8220;Legge applicabile&#8221; si intende il Regolamento (UE)
                2016/679 del Parlamento europeo e del Consiglio, del 27 aprile
                2016, relativo alla protezione delle persone fisiche con riguardo
                al trattamento dei dati personali, nonch&#233; alla libera
                circolazione di tali dati (di seguito per brevit&#224; anche
                &#8220;Regolamento&#8221;), la normativa nazionale di attuazione e
                adeguamento al Regolamento e qualsiasi altra normativa o atto
                avente forza normativa in materia di protezione dei dati personali
                applicabile in Italia, ivi compresi i provvedimenti del Garante;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                per &#8220;Misure di sicurezza&#8221; si intendono le misure minime
                di sicurezza di cui all&#8217;art. 32 del Regolamento nonch&#233;
                le misure indicate all&#8217;Allegato I al presente accordo.
            </p>
        </li>
    </ul>
    <p dir="ltr">
        <strong>2. Nomina</strong>
    </p>
    <p dir="ltr">
        Con la sottoscrizione del presente Accordo, il Titolare nomina Kromin
        S.R.L., che accetta, Responsabile del trattamento dei Dati personali ai
        sensi dell&#8217;art. 28 del Regolamento.
    </p>
    <p dir="ltr">
        <strong> 3. Durata </strong>
    </p>
    <p dir="ltr">
        L&#8217;Accordo produrr&#224; i suoi effetti dal momento
        dell&#8217;iscrizione del Titolare alla Piattaforma e per tutta la durata
        del rapporto con Kromin S.R.L. e rimarr&#224; in vigore fino alla
        cessazione di tale rapporto con il Responsabile, indipendentemente dalla
        causa di detta cessazione o, comunque, alla data di cessazione del
        trattamento dei Dati personali se, per qualsiasi ragione, successiva alla
        cessazione del rapporto con Kromin S.R.L. A partire dal momento
        dell&#8217;iscrizione del Titolare alla Piattaforma, le clausole del
        presente atto di nomina si applicheranno ai Dati personali oggetto del
        trattamento nell&#8217;ambito dei Servizi.
    </p>
    <p dir="ltr">
        <strong>4. Operazioni di trattamento, tipologia di dati personali e categorie di
            interessati </strong>
    </p>
    <p dir="ltr">
        4.1 Il presente Accordo si riferisce a tutte le operazioni di trattamento
        connesse alla fornitura dei Servizi (di seguito &#8220;Operazioni di
        trattamento&#8221;).
    </p>
    <p dir="ltr">
        4.2 Le Operazioni di trattamento saranno funzionali all&#8217;erogazione
        dei Servizi nonch&#233; a tutte le ulteriori attivit&#224; che il Titolare
        dovesse richiedere al Responsabile nell&#8217;ambito del rapporto.
    </p>
    <p dir="ltr">
        4.3 Le Operazioni di trattamento avranno ad oggetto dati personali di
        natura comune nonch&#233; dati appartenenti a particolari categorie, tra
        cui informazioni sullo stato di salute del paziente.
    </p>
    <p dir="ltr">
        4.4 Il Responsabile tratter&#224; i Dati personali riferiti ai pazienti che
        abbiano richiesto l&#8217;assistenza del Medico curante.
    </p>
    <p dir="ltr">
        <strong>5. Obblighi del Responsabile</strong>
    </p>
    <p dir="ltr">
        Fermo restando ogni altro obbligo previsto dalla normativa applicabile, il
        Responsabile si impegna a:
    </p>
    <p dir="ltr">
        a. utilizzare i Dati personali esclusivamente per le finalit&#224; indicate
        nel presente Accordo, nonch&#233; per le finalit&#224; ulteriori che il
        Titolare dovesse eventualmente e successivamente indicare, e comunque
        esclusivamente per l&#8217;esecuzione dei Servizi;
    </p>
    <p dir="ltr">
        b. trattare i Dati personali in piena conformit&#224; alle istruzioni qui
        fornite da parte del Titolare o di quelle ulteriori che il Medico curante
        dovesse in futuro ritenere opportuno fornire per la migliore e pi&#249;
        efficiente esecuzione delle attivit&#224;; le ulteriori istruzioni che
        dovessero essere fornite, saranno rese al Responsabile per iscritto o
        trasmesse al Responsabile a mezzo posta elettronica certificata;
    </p>
    <p dir="ltr">
        c. garantire che tutte le persone che agiscono sotto la propria
        autorit&#224; alle quali sia consentito di accedere o anche trattare i dati
        ricevuti abbiano sottoscritto idoneo impegno, o siano altrimenti comunque
        adeguatamente vincolate, a mantenere totale riservatezza rispetto a tali
        dati e che, a tal fine, gli stessi agiscano sotto il costante controllo del
        Responsabile ed in conformit&#224; alle istruzioni da quest&#8217;ultimo
        fornite;
    </p>
    <p dir="ltr">
        d. non comunicare a terzi e, pi&#249; in generale, non diffondere i dati
        ricevuti, se non in presenza di adeguati presupposti di liceit&#224; per
        tali ulteriori trattamenti;
    </p>
    <p dir="ltr">
        e. tenere i Dati personali separati dai dati trattati per conto di altri
        soggetti, sulla base di un criterio di sicurezza di tipo logico, e
        garantire che ogni copia dei Dati personali detenuti &#8211; anche dai
        propri dipendenti, sub-contraenti, agenti ed associati &#8211; sia
        definitivamente distrutta quando non sia pi&#249; necessaria per
        l'espletamento dei Servizi oppure restituita al Medico curante , su
        richiesta di quest&#8217;ultimo, assieme a qualsiasi mezzo o documento
        contenente Dati personali, conformemente alle disposizioni contenute nella
        Legge applicabile. Resta inteso che il Medico curante sar&#224; obbligato a
        fornire al Responsabile ogni dato o informazione da egli distrutto o
        restituito che risultasse necessario al Responsabile per difendere un
        proprio diritto in ogni ipotesi di contestazione da parte di terzi connessa
        all&#8217;erogazione dei Servizi;
    </p>
    <p dir="ltr">
        f. trattare i Dati personali sul territorio italiano o comunque
        all&#8217;interno dello Spazio Economico Europeo. Il trasferimento di dati
        verso Paesi non appartenenti allo Spazio Economico Europeo potr&#224;
        avvenire solo previa esplicita autorizzazione scritta da parte del Titolare
        e nel rispetto della Legge applicabile;
    </p>
    <p dir="ltr">
        g. adottare idonea procedura di gestione delle violazioni della sicurezza
        da cui derivino, accidentalmente o in modo illecito, la distruzione, la
        perdita, la modifica, la divulgazione non autorizzata o l&#8217;accesso ai
        Dati personali o, in alternativa, conformarsi e dare adeguata attuazione
        alla corrispondente policy che dovesse essere trasmessa da parte del Medico
        curante, prestando in ogni caso la massima collaborazione nei confronti
        dello stesso al fine di eliminare o quantomeno ridurre al minimo gli
        impatti derivanti da eventi di questo tipo;
    </p>
    <p dir="ltr">
        h. fermo l&#8217;obbligo di notificare senza ingiustificato ritardo ogni
        possibile evento qualificabile come data breach ai sensi della Legge
        applicabile, ad informare prontamente lo stesso riguardo a qualsiasi
        ulteriore evento, fatto o circostanza, prevedibile o meno, da cui possa
        derivare un rischio elevato per i diritti e le libert&#224; fondamentali
        degli interessati coinvolti nelle operazioni di trattamento;
    </p>
    <p dir="ltr">
        i. collaborare con il Medico curante limitatamente ai trattamenti relativi
        ai Dati personali, nell&#8217;assolvimento degli obblighi di:
    </p>
    <ol type={'i'}>
        <li dir="ltr">
            notifica delle violazioni di dati al Garante o ad altre autorit&#224; di
            controllo competenti e, laddove richiesto in ragione dell&#8217;elevato
            livello di rischio per i diritti e le libert&#224; degli interessati, anche
            a questi ultimi;
        </li>
        <li dir="ltr">
            se necessario, esecuzione di idonea valutazione di impatto sulla
            protezione dei dati, oltre che nello svolgimento delle procedure di
            consultazione preventiva con il Garante o le altre autorit&#224;
            competenti;
        </li>
    </ol>
    <p dir="ltr">
        j. al ricorrere dei presupposti, predisporre e mantenere costantemente
        aggiornato, in formato elettronico o cartaceo, un registro di tutte le
        operazioni di trattamento svolte ai fini dell&#8217;esecuzione
        dell&#8217;Accordo, contenente in particolare:
    </p>
    <ol type={'i'}>
        <li dir="ltr">
            i propri dati di contatto, oltre a quelli del Titolare e, ove
            applicabile, del proprio responsabile della protezione dei dati;
        </li>
        <li dir="ltr">
            le categorie dei trattamenti effettuati per conto del Titolare;
        </li>
        <li dir="ltr">
            dettagli relativi ad eventuali trasferimenti dei dati ricevuti al di
            fuori dello Spazio Economico Europeo, con indicazione del paese terzo o
            dell&#8217;organizzazione verso cui i dati sono trasmessi e, qualora il
            trasferimento non sia basato su una decisione di adeguatezza della
            Commissione UE o su altri meccanismi di garanzia (quali ad esempio Clausole
            Contrattuali Standard o Binding Corporate Rules) e non sia applicabile
            nessuna delle deroghe previste dalla normativa vigente, le misure
            implementate a tutela dei dati;
        </li>
        <li dir="ltr">
            una descrizione generale delle misure di sicurezza tecniche e
            organizzative adottate in conformit&#224; al presente atto di nomina e,
            pi&#249; in generale, alla normativa applicabile.
        </li>
    </ol>
    <p dir="ltr">
        k. mettere il Titolare al corrente, riguardo a qualsiasi richiesta di
        esercizio di diritti che il Responsabile dovesse ricevere da parte degli
        Interessati entro un termine massimo di 24 ore dal ricevimento della
        stessa;
    </p>
    <p dir="ltr">
        l. segnalare al Medico curante se le istruzioni ricevute comportano una
        violazione della Legge applicabile;
    </p>
    <p dir="ltr">
        m. prestare al Medico curante ogni necessaria collaborazione
        nell&#8217;assolvimento di richieste che dovessero pervenire dal Garante o
        da altre autorit&#224; competenti o in relazione a procedure o ispezioni
        che dovessero essere avviate nei confronti del Medico curante, dando
        altres&#236; immediata esecuzione alle istruzioni ricevute e fornendo copia
        di ogni documento richiesto;
    </p>
    <p dir="ltr">
        n. attribuire le funzioni di amministrazione di sistema previa valutazione
        dell&#8217;esperienza, della capacit&#224; e dell&#8217;affidabilit&#224;
        del soggetto designato; il Responsabile dovr&#224; designare per iscritto
        gli amministratori di sistema indicando gli ambiti di operativit&#224;
        consentiti in base ai profili di autorizzazione assegnati e riportando
        l&#8217;elenco degli amministratori di sistema in un documento da mantenere
        aggiornato e disponibile in caso di accertamenti anche da parte del
        Garante. Il Responsabile del trattamento, ai sensi del provvedimento del
        Garante del 27.11.2008 recante &#8220;Misure ed accorgimenti prescritti al
        Titolare dei trattamenti effettuati con strumenti elettronici relativamente
        alle attribuzioni delle funzioni di amministratore di sistema&#8221; e
        s.m.i., deve conservare direttamente e specificamente gli estremi
        identificativi delle persone fisiche preposte quali amministratori di
        sistema; il Responsabile dovr&#224; inoltre predisporre sistemi idonei alla
        registrazione, e alla relativa conservazione, degli accessi logici
        (autenticazione informatica) effettuati dagli amministratori di sistema ai
        sistemi di elaborazione e agli archivi elettronici.
    </p>
    <p dir="ltr">
        <strong>6. Sicurezza dei dati</strong>
    </p>
    <p dir="ltr">
        Il Responsabile si impegna ad adottare misure tecniche e organizzative
        adeguate a garantire un idoneo livello di sicurezza in riferimento ai Dati
        personali e ad adottare ogni misura &#8211; tra cui, a titolo
        esemplificativo e non esaustivo, le misure di cui all&#8217;art. 32 del
        Regolamento &#8211; necessaria a prevenire, o quantomeno minimizzare, ogni
        rischio ragionevolmente prevedibile connesso alla distruzione, alla
        perdita, alla modifica, alla divulgazione non autorizzata o
        all&#8217;accesso, in modo accidentale o illegale, ai dati ricevuti. In
        particolare, il Responsabile si impegna ad adottare tutte le Misure di
        sicurezza di cui all&#8217;Allegato I, oltre che le ulteriori misure che,
        eventualmente, il Titolare gli chiedesse di adottare in relazione a
        specifiche operazioni del trattamento.
    </p>
    <br/>
    <p dir="ltr">
        <strong>7. Subfornitori</strong>
    </p>
    <p dir="ltr">
        7.1 Il Responsabile &#232; autorizzato sin da ora ad avvalersi di
        subfornitori per l&#8217;esecuzione dei Servizi. In tal caso sar&#224; cura
        del Responsabile concludere, ai sensi dell&#8217;art. 28, par. 4, del
        Regolamento, un accordo con il quale tali subfornitori siano vincolati al
        rispetto degli stessi obblighi in materia di protezione dei dati personali
        applicabili al Responsabile in virt&#249; del presente accordo e,
        ovviamente, della normativa di volta in volta vigente. In particolare, il
        subfornitore dovr&#224; fornire sufficienti garanzie circa
        l&#8217;implementazione di adeguate misure di sicurezza tecniche ed
        organizzative, in modo da rispettare i requisiti imposti dalla Legge
        applicabile. Resta inteso tra le Parti che, qualora il subfornitore ometta
        di adempiere ai propri obblighi in materia di protezione dei dati, il
        Responsabile conserva nei confronti del Medico curante l'intera
        responsabilit&#224; dell'adempimento degli obblighi del subfornitore.
    </p>
    <p dir="ltr">
        7.2 Il Responsabile informer&#224; il Titolare di ogni intenzione di
        modifica che abbia ad oggetto l&#8217;aggiunta, la sostituzione o la
        rimozione di sub responsabili, dando conseguentemente al Medico curante
        l&#8217;opportunit&#224; di opporsi a tali modifiche.
    </p>
    <p dir="ltr">
        7.3 Fermo quanto previsto all&#8217;art. 7.1, nel caso in cui il
        Responsabile, per l&#8217;esecuzione dei Servizi si avvalesse di
        subfornitori stabiliti in paesi diversi da quelli appartenenti allo Spazio
        Economico Europeo, il trasferimento dei Dati personali sar&#224; operato
        sulla base di un accordo che sar&#224; concluso per iscritto tra il Medico
        curante, nella qualit&#224; di Titolare del trattamento esportatore, ed il
        subfornitore cui saranno trasferiti i Dati personali, nella qualit&#224; di
        importatore ai sensi dell&#8217;art. 46 Regolamento. Tale accordo
        verr&#224; redatto sulla base di una decisione di adeguatezza o, in assenza
        di questa, delle clausole tipo adottate con decisione della Commissione
        europea 2010/87/UE, o qualsiasi altra base ritenuta adeguata dalla
        Commissione per il trasferimento di tali al territorio al di fuori dello
        Spazio Economico Europeo. Resta, inoltre, inteso tra le parti che sar&#224;
        onere e responsabilit&#224; esclusiva del Responsabile far sottoscrivere le
        suddette clausole al subfornitore.
    </p>
    <p dir="ltr">
        <strong>8. Audit </strong>
    </p>
    <p dir="ltr">
        Il Responsabile si impegna a fornire al Titolare, su richiesta, relazioni e
        report che attestino l&#8217;adempimento degli obblighi gravanti sul
        Responsabile stesso ai sensi del presente Accordo, con particolare riguardo
        alle misure di sicurezza adottate.
    </p>
    <br/>
    <h5 dir="ltr">
        <strong>ALLEGATO I</strong>
    </h5>
    <p dir="ltr">
        Il presente allegato &#232; considerato parte integrante e sostanziale
        dell&#8217;accordo sul trattamento dei dati tra il Titolare e il
        Responsabile e contiene le misure minime che devono essere adottate dal
        Responsabile del trattamento per lo svolgimento delle attivit&#224; che
        richiedono il trattamento di dati di titolarit&#224; del Medico curante.
    </p>

    <p dir="ltr">
        <strong> SICUREZZA IT </strong>
    </p>
    <ul>
        <li dir="ltr">
                utilizzo di sistema di rete interno che garantisca
                un&#8217;adeguata protezione e conservazione dei file contenenti
                dati personali;
        </li>
        <li dir="ltr">
                controllo degli accessi al sistema stesso da parte di dipendenti e
                collaboratori, ai quali sia garantita la visualizzazione dei soli
                dati necessari allo svolgimento delle mansioni loro attribuite;
        </li>
        <li dir="ltr">
                tracciabilit&#224; dell&#8217;attivit&#224; di chi inserisca,
                elimini o modifichi i dati nel sistema;
        </li>
        <li dir="ltr">
                svolgimento di verifiche periodiche atte ad accertare la possibile
                vulnerabilit&#224; della rete;
        </li>
        <li dir="ltr">
                utilizzo di software antivirus periodicamente aggiornati;

        </li>
        <li dir="ltr">

                adozione di sistemi di firewalls e sistemi di backup e recupero
                dati;

        </li>
        <li dir="ltr">

                svolgimento di verifiche periodiche per accertare la
                funzionalit&#224; delle misure implementate a garantire la
                sicurezza informatica.

        </li>
    </ul>

    <p dir="ltr">
        <strong>SICUREZZA FISICA </strong>
    </p>
    <ul>
        <li dir="ltr">

                adozione di misure organizzative atte a sensibilizzare i dipendenti
                e i collaboratori a non lasciare copie di documenti cartacei
                contenenti dati personali in luoghi che permettano la
                visualizzazione da parte di soggetti non autorizzati e,
                soprattutto, sulla loro postazione di lavoro;

        </li>
        <li dir="ltr">

                adozione di misure atte a proteggere i documenti cartacei
                contenenti dati personali contro furto, perdita, accesso o
                modifiche non autorizzate;

        </li>
    </ul>
    <p dir="ltr">
        <strong>CONTROLLO DEGLI ACCESSI AL SISTEMA</strong>
    </p>
    <ul>
        <li dir="ltr">

                limitazione dell&#8217;accesso ai dati personali ai soli dati
                necessari allo svolgimento delle loro attivit&#224;;

        </li>
        <li dir="ltr">
                adozione di procedure di sicurezza aziendale atte ad evitare che
                soggetti non autorizzati possano accedere ai dati personali
                contenuti negli archivi informatici;

        </li>
        <li dir="ltr">

                procedura di accesso ai sistemi dove sono conservati dati personali
                che preveda l&#8217;utilizzo di credenziali di autenticazione (user
                ID e password) con differenti livelli di autorizzazione
                d&#8217;accesso;

        </li>
        <li dir="ltr">

                adozione di misure organizzative atte a sensibilizzare i dipendenti
                e collaboratori ad effettuare il &#8220;logout&#8221; del sistema
                ogni volta che si allontanino della loro postazione di lavoro;

        </li>
        <li dir="ltr">

                adozione di procedura di sicurezza che richieda periodicamente il
                cambiamento della password adottata dal dipendente o collaboratore.

        </li>
    </ul>
    <p dir="ltr">
        <strong>SUBFORNITORI</strong>
    </p>
    <ul>
        <li dir="ltr">

                adozione di procedura che permetta valutare correttamente, prima di
                avvalersi di un soggetto come fornitore, che lo stesso sia in grado
                di porre in essere misure minime di sicurezza che gli permettano di
                trattare i dati forniti in modo sicuro e confidenziale;

        </li>
        <li dir="ltr">

                imposizione ai propri subfornitori, tramite accordi sul trattamento
                dei dati, di obblighi in materia di sicurezza adeguati a garantire
                la protezione dei dati.

        </li>
    </ul>
    <p dir="ltr">
        <strong> MISURE CONTRO LA PERDITA O INDISPONIBILIT&#192; DEI DATI PERSONALI</strong>
    </p>
    <ul>
        <li dir="ltr">

                adozione di misure di sicurezza atte a garantire la protezione
                contro la perdita e/o indisponibilit&#224; accidentale o illecita
                dei dati personali;

        </li>
        <li dir="ltr">

                realizzazione di backup periodici dei set di dati contenenti dati
                personali;

        </li>
        <li dir="ltr">

                realizzazione di verifiche periodiche delle copie di backup, al
                fine di garantire che siano, effettivamente, a disposizione del
                sistema;

        </li>
        <li dir="ltr">

                adozione procedure interne per la gestione degli incidenti di
                sicurezza informatica in modo tempestivo ed efficace;

        </li>
        <li dir="ltr">

                conservazione dei dati personali in modo completo ed attuale
                durante tutta l&#8217;attivit&#224; di trattamento.

        </li>
    </ul>
</div>


export {PrivacyPage, PrivacyDoctorPage, TermsDoctorContent}
