import React from "react";
import classes from "./UserCard.module.scss";
import {Link, NavLink} from "react-router-dom";
import doctor from '../../images/doctor-icon.svg';
import user from '../../images/paziente-icon.svg';
import company from '../../images/company.svg';
import employee from '../../images/employee.svg';

const UserCard = ({icon, title, message, ctaLink, ctaText}) =>
    <NavLink to={ctaLink}>
        <div className={classes.card}>
            <div className="row align-items-center">
                <div className="col-md-12 col-3">
                    <img src={icon === "user" ? user : icon === "doctor" ? doctor : icon === "employee" ? employee : company} alt={"icon"}/>
                </div>
                <div className="col-md-12 col-9">
                    <h3 className={classes.title}> {title}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p className={classes.message}>{message}</p>
                    <Link className={classes.cta} to={ctaLink}> {ctaText} </Link>
                </div>
            </div>
        </div>
    </NavLink>

export default UserCard;