export function changeCaseFirstLetter(params) {
    if(typeof params === 'string') {
        return params.charAt(0).toUpperCase() + params.slice(1);
    }
    return null;
}


export const flatAnswersToArray = (survey) => {
    let responses = [];
    survey.nodes.filter(e => e.selected).map((e, index) => {
        responses[index] = [];
        responses[index].push(e.text);
        responses[index].push(...answerNodeParser(e));
    });
    return responses;
};

export const answerNodeParser = (node) => {
    let resp = [];
    for(const x in node.nodes ){
        /*resp.push(`${changeCaseFirstLetter(node.nodes[x].text)}`);*/ // Save question
        for(const y in node.nodes[x].nodes){
            if(node.nodes[x].nodes[y].selected){
                resp.push(changeCaseFirstLetter(node.nodes[x].nodes[y].text));
                resp.push(...answerNodeParser(node.nodes[x].nodes[y]));
            }
        }
    }
    return resp;
};
