import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Header from "../../Components/Header/Header.component";
import Footer from "../../Components/Footer/Footer.component";
import classes from './Team.module.scss';

import carlo from '../../images/team-photos/carlo-angeletti.jpg';
import emanuele from '../../images/team-photos/emanuele-esposito.jpg';
import francesco from '../../images/team-photos/francesco-monti.jpg';
import manuel from '../../images/team-photos/manuel-fontana.jpg';
import marco from '../../images/team-photos/marco-ciotoli.jpg';
import matteo from '../../images/team-photos/matteo-pulcrano.jpg';
import pasquale from '../../images/team-photos/pasquale-gallo.jpg';
import sara from '../../images/team-photos/sara-mastrantonio.jpg';
import vincenzo from '../../images/team-photos/vincenzo-ruffa.jpg';
import alessandro from  '../../images/team-photos/alessandro-boccanelli.jpg';
import marcello from '../../images/team-photos/marcello-marcelli.jpg';
import maurizio from '../../images/team-photos/maurizio-de-lisi.jpg';
import loredana from '../../images/team-photos/loredana-bove.jpg';
import picampus from '../../images/logo-picampus.png';
import salute from '../../images/logo-salute-e-societa.png'
import Logo from "../../Components/Shared/Logo/Logo.component";

class Team extends Component {

    state = {};

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={`${classes.pageWrap}`}>
                <Header/>
                <div className={`${classes.main}`}>
                    <section className={`${classes.advisors}`}>
                        <div className={`container`}>
                            <div className={`row align-items-center`}>
                                <div className={`col-12 col-md-4 offset-md-4 text-center`}>
                                    <div className={`${classes.mainLogo}`} style={{padding:'0 20%'}}>
                                        <img src={salute} style={{maxWidth:'100%'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`row align-items-center`}>
                                <div className={`col-12 text-center`}>
                                    <h2 style={{marginTop:'30px'}}>Ideatori e advisor medico scientifici</h2>
                                </div>
                            </div>
                            <div className={`row`}>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={marcello}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Marcello Marcelli
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Direttore U.O.C. Nutrizione Clinica, San Giovanni
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={alessandro}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Alessandro Boccanelli
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Cardiologo, Presidente Salute e Società
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={maurizio}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Maurizio De Lisi
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Direttore Unità Operativa Pneumologia San Camillo
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className={`row`}>
                                <div className="col-12 col-md-4 offset-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={loredana}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Loredana Bove
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Direttrice U.O.C. Neurologia San Giovanni
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </section>
                    <section className={`${classes.team}`}>
                        <div className={`container`}>
                            <div className={`row align-items-center`}>
                                <div className={`col-12 col-md-4 offset-md-4 text-center`}>
                                    <div className={`${classes.mainLogo}`}>
                                        <Logo/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-8 offset-md-2 text-center">
                                    <p className={`${classes.subtitle}`} style={{marginBottom: '60px'}}>
                                        Lavoriamo con attenzione ad ogni nuovo progetto: ecco una parte del
                                        nostro Team che ha sviluppato con passione <span
                                        className={`${classes.enhanced}`}>comestai.org.</span>
                                    </p>
                                </div>
                            </div>
                            <div className={`row align-items-center`}>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={emanuele}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Emanuele Esposito
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Founder | Marketing Strategist
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={marco}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Marco Ciotoli
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Senior Consultant | Front-end Dev
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={vincenzo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Vincenzo Ruffa
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Senior Consultant | Back-end Dev
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={pasquale}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Pasquale Gallo
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Copywriter
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={carlo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Carlo Angeletti
                                            </div>
                                            <div className={`${classes.role}`}>
                                                IT & Developer
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={francesco}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Francesco Monti
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Front-end Dev
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={matteo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Matteo Pulcrano
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Back-end Dev
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={manuel}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Manuel Fontana
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Full Stack Dev
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className={`${classes.teamItemWrapper}`}>
                                        <div className={`${classes.teamItemPhoto}`}>
                                            <div className={`${classes.photoWrapper}`}>
                                                <div className={`${classes.photoBg}`}>
                                                    <img
                                                        src={sara}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.description}`}>
                                            <div className={`${classes.title}`}>
                                                Sara Mastrantonio
                                            </div>
                                            <div className={`${classes.role}`}>
                                                Designer
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className={`${classes.contributors}`}>
                        <div className={`container`}>
                            <div className={`row align-items-center`}>
                                <div className={`col-12 text-center`}>
                                    <h2>Contributors</h2>
                                    <p className={`${classes.subtitle}`}>Supporto risorse ed infrastrutture</p>
                                </div>
                            </div>
                            <div className={`row align-items-center`}>
                                <div className={`col-12 col-md-6 offset-md-3 text-center`}>
                                  <div className={`${classes.contributorsWrapper}`}>
                                      <img
                                          src={picampus}
                                      />
                                  </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>
                <Footer/>
            </div>
        );
    }
}

export default Team;
