import React, {Component} from 'react';
import logo from "../../images/logo-comestai.svg"
import {Link, NavLink, withRouter} from "react-router-dom";
import classes from './Header.module.scss';

class Header extends Component {

    render() {
        const {hasNav,match:{params:{doctor}}} = this.props;
        const url = doctor ? `/${doctor}`: '/'
        return (
            <header>
                <div className={`${classes.header}`}>
                    <NavLink to={url} className={`${classes.logo}`}>
                        <img src={logo}/>
                    </NavLink>
                    {hasNav &&
                    <nav className={`${classes.mainNav}`}>
                        <ul className={`${classes.mainNavList}`}>
                            <li>
                                <a href="https://dashboard.comestai.org/" target={`_blank`}>Area riservata</a>
                            </li>
                        </ul>
                    </nav>}
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
