import React, {Component} from 'react';
import './App.scss';
import Survey from "./Components/Survey/Survey";
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "./Pages/Home/Home.component";
import ComeBack from "./Pages/ComeBack/ComeBack.component";
import Team from "./Pages/Team/Team.component";
import {PrivacyPage,PrivacyDoctorPage} from "./Pages/StaticPages/StaticPages.component";
import DoctorApplication from "./Pages/DoctorApply/DoctorApplication.page";
import {withTracker} from "./Components/Tracker";
import HealedPatient from "./Pages/HealedUser/HealedPatient.page";
import BusinessSurvey from "./Components/Survey/BusinessSurvey";
import BusinessApply from "./Pages/BusinessApply/BusinessApply";
import HealedEmployee from "./Pages/HealedUser/HealedEmployee";

class App extends Component {
	render() {
		return (
			<div>
                <Switch>
					<Route exact path=":doctor?/business-survey/:question?" component={withTracker(BusinessSurvey)}/>
                    <Route exact path="/:doctor?/survey/:question?" component={withTracker(Survey)}/>
					<Route exact path="/:doctor?/about" component={withTracker(Team)}/>
					<Route exact path="/:doctor?/doctor-apply" component={withTracker(DoctorApplication)}/>
					<Route exact path="/:doctor?/business-apply" component={withTracker(BusinessApply)}/>
					{/*<Route exact path="/debug" component={withTracker(ComeBack)}/>*/}
					<Route exact path="/:doctor?/torna-domani" component={withTracker(ComeBack)}/>
					<Route exact path="/paziente/sono-guarito/:token?" component={withTracker(HealedPatient)}/>
					<Route exact path="/dipendente/sono-guarito/:token?" component={withTracker(HealedEmployee)}/>
					<Route exact path="/:doctor?/privacy" component={withTracker(PrivacyPage)}/>
					<Route exact path="/:doctor?/privacy-doctor" component={withTracker(PrivacyDoctorPage)}/>
					<Route exact path="/:doctor?" component={withTracker(Home)}/>
					<Redirect to={"/"}/>
                </Switch>
			</div>
		);
	}
}

export default App;
