import React, {Component} from 'react';
import classes from './Footer.module.scss';
import logo from "../../images/logo-comestai-footer.svg";
import {NavLink, withRouter} from "react-router-dom";

class Footer extends Component {

    state = {
        doctorTermsVisible: false
    };

    componentDidMount() {
        const {history:{location:{pathname}}} = this.props;
        if(pathname === "/doctor-apply"){
            this.setState({
                doctorTermsVisible:true
            })
        }
    }

    render() {
        const {doctorTermsVisible} = this.state;
        const {match:{params:{doctor}}} = this.props;
        const url = doctor ? `/${doctor}`: ''

        return (
            <footer className={`${classes.footer}`}>
                <div className={`${classes.ftMain}`}>

                    <div className={`${classes.ftMainItem} ${classes.disclaimer}`}>
                        <div className={`${classes.logo}`}>
                            <img src={logo}/>
                        </div>
                        <p style={{color: '#3e515e', fontSize: '14px'}} className={`${classes.disclaimerText}`}>
                            <strong>COME STAI</strong> non è un servizio di emergenza e non sostituisce il servizio del
                            <strong> Numero Unico delle Emergenze 112 </strong> o <strong>118</strong> che deve essere attivato da parte del cittadino in caso di
                            emergenza sanitaria. I dati personali inviati verranno trattati solo per le finalità
                            connesse all’utilizzo della piattaforma e verranno anonimizzati entro 6 mesi dalla fine
                            dello stato d’emergenza.
                        </p>
                    </div>
                    <div className={`${classes.ftMainItem} ${classes.list}`}>
                        <ul>
                            <li><NavLink to={`${url}/privacy`}>Privacy Policy</NavLink></li>
                            {doctorTermsVisible && <li><NavLink to={'/privacy-doctor'}>Termini & Privacy Medico</NavLink></li>}
                            <li><a href="https://www.iubenda.com/privacy-policy/57543263/cookie-policy"
                                   className="iubenda-nostyle no-brand iubenda-embed" title="Cookie Policy ">Cookie
                                Policy</a></li>
                            {/*<li><NavLink to={'/'}>Terms of service</NavLink></li>
                            <li><NavLink to={'/'}>Legal</NavLink></li>*/}
                            <li><NavLink to={`${url}/about`}>Team</NavLink></li>
                        </ul>
                    </div>
                </div>
                <div className={`${classes.ftBottom}`}>
                    <div className={`${classes.ftMainItem}`}>
                        <span>
                            Creato con ❤ da <a href="https://kromin.it/" target={`_blank`} style={{
                            textDecoration: 'none',
                            fontWeight: 800,
                            color: '#2d92c0'
                        }}>Kromin️</a> per Zia Anna
                        </span>
                    </div>
                    <div className={`${classes.ftMainItem} ${classes.copyright}`}>
                        © 2020 ComeStai. Tutti i diritti riservati.
                    </div>
                </div>
            </footer>
        );
    }
}

export default withRouter(Footer);
