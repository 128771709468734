import React, {Component} from 'react';
import classes from './InfoBlock.module.scss';
import PropTypes from "prop-types";

class InfoBlock extends Component {
	static propTypes = {
		value: PropTypes.number.isRequired,
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		selected: PropTypes.any.isRequired,
		onChangeHandle: PropTypes.func,
		isBusiness: PropTypes.bool
	};

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	render() {
		const {value, id, title, description, selected, onChangeHandler,isBusiness} = this.props;
		const {handleClick} = this;
		const checked = selected ? classes.boxActive : '';
		return (
			<div className={`${classes.box} ${checked} text-center text-md-left ${isBusiness ? classes.box_alt : ''}`} onClick={handleClick}>
				<h3 className={classes.boxTitle} htmlFor={id}>{title}
					<input type="radio" id={id} value={value} checked={selected} onChange={event => handleClick()}/>
				</h3>
				<p className={`d-none d-md-block ${classes.boxDescription}`}>{description}</p>
			</div>
		)
	}

	handleClick = () => {
		const {onChangeHandler, value} = this.props;
		onChangeHandler(value);
	}
}

export default InfoBlock;
