import React, {Component} from 'react';
import PropTypes from "prop-types";


class MultipleAnswer extends Component {
	static propTypes = {
		answers: PropTypes.array.isRequired,
		onSelect: PropTypes.func.isRequired,
		toggleSignAnswers: PropTypes.func.isRequired,
		goBack: PropTypes.func.isRequired,
		currentNode: PropTypes.number,
		match: PropTypes.any
	};

	state = {
		selected: {}
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		const {answers, currentNode} = this.props;
		const {selected} = this.state;
		const atLeastOneSelected = Object.keys(selected).map(e=>selected[e]).filter(e=> e).length;

		return (
			<div>
				<div className="answers-list">
					{answers.map((e, index) => {
						return <div className="form-group customCheckmark multipleCheck" key={index}>
							<input type="checkbox" className="form-check-input" checked={selected[index]}
								   onClick={this.onHandleCheckbox(index)}/>
							<label className="form-check-label survey-answer" htmlFor="exampleCheck1"
								   onClick={this.onHandleCheckbox(index)}>{e.text}</label>
						</div>
					})}
				</div>
				<div className="btns-container">
					<div className="back">
						<a className=" text-left" onClick={this.props.goBack}>{`<`} Indietro</a>
					</div>
					<div className="next">
						<button className="btn btn-default text-center" onClick={this.onGoNext}
								disabled={!atLeastOneSelected}> Avanti
						</button>
					</div>
				</div>
				{/*<div className="row">
					<div className="col-12 col-md-6">

					</div>
				</div>*/}
			</div>
		);
	}

	onHandleCheckbox = (index) => (e) => {
		let {selected} = this.state;
		selected[index] = !selected[index];
		this.setState({
			selected: selected
		})
	};

	haveSelectedAnswers = () => {
		const {selected} = this.state;
		let status = false;
		const keys = Object.keys(selected);
		for (const index in keys) {
			if (selected[index]) {
				status = true;
				break;
			}
		}
		return status;
	};

	onGoNext = () => {
		const {answers} = this.props;
		const {selected} = this.state;
		let nodes = [];
		let ids = [];
		Object.keys(selected).map(e => {
			if(selected[e]){
				ids.push(answers[e].id);
				nodes.push(...answers[e].nodes);
			}
		});
		this.props.toggleSignAnswers(ids);
		this.props.onSelect(nodes);
		this.setState({
			selected: {}
		});
	}
}

export default MultipleAnswer;
