import {post, get} from "axios";

/**
 *
 * @param token

 * @returns {*}
 */
export const httpSetPatientHealed = (token) => {
    const url = `patient/set-guarito/${token}`;
    return get(url, token);
};

/**
 *
 * @param token

 * @returns {*}
 */
export const httpSetEmployeeHealed = (token) => {
    const url = `employee/set-guarito/${token}`;
    return get(url, token);
};


