import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import {traverse} from "../../Helpers/Walk";
import {httpGetTemplateSurvey} from "../../http/Survey.http";
import SurveyQuestionList from "./SurveyQuestionList/SurveyQuestionList";
import CurrentState from "../../Pages/CurrentState/CurrentState.component";
import ThankYou from "../../Pages/ThankYou/ThankYou.component";

class Survey extends Component {

	state = {
		step: 0,
		nodes: [],
		flatEntriesLength: [],
		flatSelectedAnswersID: [],
		originalSurvey: null,
		survey: null,
		surveyId: null,
		surveyTitle: '',
		currentNode: 0,
		notExist: false,
		isLoading: true,
		currentStateId: null,
	};
	componentDidMount() {
		this.readRouteParams();
	};

	componentDidUpdate(prevProps) {
		const {match: {params: {question,doctor}}} = this.props;
		const url = doctor ? `/${doctor}/survey`: '/survey'

		let {nodes, currentNode, flatEntriesLength, flatSelectedAnswersID, survey, originalSurvey} = this.state;
		window.onpopstate = () => {

			/*console.log("Back button press...");
			console.log("Survey | currentNode after goBack: " + currentNode);
			console.log("flatEntriesLength: " + flatEntriesLength.length);*/

			if (isNaN(currentNode)) {
				currentNode = -1;
				//console.log("First question? Node was NaN... set back to -1");
			}
			if (currentNode > flatEntriesLength.length) {
				/*console.log("Faccio redirect all'inizio")
				console.log([originalSurvey])*/
				return this.setState({
					survey: cloneDeep(originalSurvey),
					step: 0,
					currentNode: 0,
					flatSelectedAnswersID: [],
					nodes: [cloneDeep(originalSurvey)],
					flatEntriesLength: []
				}, () => this.props.history.push(url));
			}
			if (currentNode >= 0 && flatEntriesLength.length > 0) {

				/*console.log("CurrentNode is " + currentNode);
				console.log("Simulate goBack on currentNode>0");*/

				// Aumento di uno per annullare gli effetti del passo fatto prima
				currentNode = currentNode + 1;
				// Rimuovo le Selected dalla Survey usata per il Recap
				survey = this.traverseSurvey(flatSelectedAnswersID[currentNode - 1]);
				// Rimuovo l'ultima risposta data dall'array delle risposte selezionate
				flatSelectedAnswersID.splice(currentNode - 1, 1);
				// Rimuovi i nodi successivi che erano stati appesi
				nodes.splice(currentNode, flatEntriesLength[currentNode - 1]);
				// Diminuisco la lunghezza dall'array delle risposte selezionate
				flatEntriesLength.splice(currentNode - 1, 1);

				//Reimposta lo stato al passo precedente
				this.setState({
					survey: survey,
					currentNode: currentNode - 1,
					step: 1,
					nodes: nodes,
					flatSelectedAnswersID: flatSelectedAnswersID,
					flatEntriesLength: flatEntriesLength
				});
			} else {
				/*console.log("Faccio redirect all'inizio 2")*/
				this.setState({
					survey: cloneDeep(originalSurvey),
					step: 0,
					currentNode: 0,
					flatSelectedAnswersID: [],
					nodes: [cloneDeep(originalSurvey)],
					flatEntriesLength: []
				}, () => this.props.history.push(url));
			}
		};

		if (prevProps.match.params.question !== question) {
			this.setState({
				currentNode: +question
			});
		}
	}

	render() {
		const {toggleSignAnswers, onSelect, goBack, saveCurrentStateId, goToStep, startAgain} = this;
		const {nodes, currentNode, survey, surveyId, notExist, isLoading, step, currentStateId} = this.state;
		const {history} = this.props;
		const node = nodes[currentNode];
		switch (step) {
			case 0:
				return <CurrentState saveCurrentStateId={saveCurrentStateId} currentStateId={currentStateId}
									 goToStep={goToStep} startAgain={startAgain}/>
			case 1:
				return <SurveyQuestionList isLoading={isLoading} survey={survey} node={node} currentNode={currentNode}
										   goBack={goBack}
										   toggleSignAnswers={toggleSignAnswers} onSelect={onSelect} surveyId={surveyId}
										   notExist={notExist}/>
			case 2:
				return <ThankYou goBack={goBack} history={history} currentStateId={currentStateId} survey={survey} surveyId={surveyId}/>
			default:
				return <h1>404</h1>
		}
	}

	saveCurrentStateId = (id) => {
		const {match: {params: {doctor}}} = this.props;
		const url = doctor ? `/${doctor}/survey`: '/survey'
		this.setState({
			currentStateId: id,
			step: 1
		}, () => {
			this.props.history.push(`${url}/0`);
		})
	};

	goToStep = (step) => {
		const {match: {params: {doctor}}} = this.props;
		const url = doctor ? `/${doctor}/survey`: '/survey'
		this.setState({
			step: step
		}, () => {
			if (step === 1) {
				this.props.history.push(`${url}/0`);
			}
		})
	}

	readRouteParams = async () => {
		let currentNode = this.props.match.params.question;
		const {match: {params: {doctor}}} = this.props;
		const url = doctor ? `/${doctor}/survey`: '/survey'
		try {
			const {data: template} = await httpGetTemplateSurvey();
			const survey = JSON.parse(template.survey.json);

			this.setState({
				isLoading: false,
				originalSurvey: cloneDeep(survey),
				survey: cloneDeep(survey),
				surveyId: template.survey.id,
				surveyTitle: template.title,
				nodes: [cloneDeep(survey)],
				currentNode: currentNode ? +currentNode : 0,
			}, () => {
				const {nodes, currentNode} = this.state;
				if (!nodes[currentNode]) {
					this.props.history.push(url);
				}
			});

		} catch (error) {
			this.setState({
				isLoading: false,
				notExist: !this.state.notExist
			})
		}
	};

	traverseSurvey = (ids) => {
		let survey = cloneDeep(this.state.survey);
		ids.map(e => {
			survey = traverse(survey, e)
		});

		return survey
	};

	toggleSignAnswers = (ids) => {
		let {flatSelectedAnswersID} = this.state;
		flatSelectedAnswersID.push(ids);
		this.setState({
			survey: this.traverseSurvey(ids),
			flatSelectedAnswersID: flatSelectedAnswersID

		})
	};

	goBack = () => {
		let {nodes, currentNode, flatEntriesLength, flatSelectedAnswersID,originalSurvey} = this.state;
		const {match: {params: {doctor}}} = this.props;
		const url = doctor ? `/${doctor}/survey`: '/survey'
		const flatNodeIndex = currentNode - 1;
		if (flatNodeIndex < 0) {
			return this.setState({
				survey: cloneDeep(originalSurvey),
				step: 0,
				currentNode: 0,
				flatSelectedAnswersID: [],
				nodes: [cloneDeep(originalSurvey)],
				flatEntriesLength: []
			}, () => this.props.history.push(url));
		} else {
			const survey = this.traverseSurvey(flatSelectedAnswersID[flatNodeIndex]);
			nodes.splice(currentNode, flatEntriesLength[flatNodeIndex]);
			flatSelectedAnswersID.splice(flatNodeIndex, 1);
			flatEntriesLength.splice(flatNodeIndex, 1);
			this.setState({
				currentNode: currentNode - 1,
				survey: survey,
				step: 1,
				nodes: nodes,
				flatSelectedAnswersID: flatSelectedAnswersID,
				flatEntriesLength: flatEntriesLength
			}, () => {
				this.props.history.push(`${url}/${+currentNode - 1}`);
			});
		}
	};

	startAgain = () => {
		const {match: {params: {doctor}}} = this.props;
		const url = doctor ? `/${doctor}`: '/'
		this.setState({
			step: 0,
			currentStateId: null,
		}, () => {
			this.props.history.push(url);
		});
	};

	onSelect = (newNodes) => {
		const {match: {params: {doctor}}} = this.props;
		const url = doctor ? `/${doctor}/survey`: '/survey'
		let {nodes, currentNode, flatEntriesLength} = this.state;
		flatEntriesLength.push(newNodes.length);
		nodes.splice(currentNode + 1, 0, ...newNodes);
		this.setState({
			nodes: nodes,
			step: nodes[currentNode + 1] ? 1 : 2,
			flatEntriesLength: flatEntriesLength
		}, () => {
			this.props.history.push(`${url}/${+currentNode + 1}`);
		});

	}
}

export default Survey;
