import axios, {post, get} from 'axios';

/**
 * get a specific template
 * @returns {*}
 */
export const httpGetTemplateSurvey = () => {
    let url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/survey-json/survey.json`;
    if(process.env.REACT_APP_DEBUG_SURVEY === 'true') {
        url = "/survey/last";
    }

    return get(url);
};

/**
 * get all patients statuses for first part of survey
 * @returns {*}
 */
export const httpGetPatientsStatuses = () => {
    const url = "/patients_statuses";
    return get(url);
};

/**
 * get all pathologies for the end of survey
 * @returns {*}
 */
export const httpGetPathologies = () => {
    const url = "/pathologies";
    return get(url);
};

/**
 * save the answered survey
 * @param answeredSurvey
 * @param answeredSurvey.patient_status_id
 * @param answeredSurvey.full_name
 * @param answeredSurvey.fiscal_code
 * @param answeredSurvey.address
 * @param answeredSurvey.zip
 * @param answeredSurvey.mobile_number
 * @param answeredSurvey.notes
 * @param answeredSurvey.pathologies
 * @param answeredSurvey.email
 * @param answeredSurvey.json
 * @param answeredSurvey.smoker
 * @param answeredSurvey.height
 * @param answeredSurvey.weight
 * @returns {*}
 */
export const httpSaveAnsweredSurvey = (answeredSurvey) => {
    const url = "/answers";
    return post(url, answeredSurvey);
};

/**
 * save the answered survey Business
 * @param answeredSurvey
 * @param answeredSurvey.patient_status_id
 * @param answeredSurvey.full_name
 * @param answeredSurvey.fiscal_code
 * @param answeredSurvey.address
 * @param answeredSurvey.zip
 * @param answeredSurvey.mobile_number
 * @param answeredSurvey.notes
 * @param answeredSurvey.pathologies
 * @param answeredSurvey.email
 * @param answeredSurvey.json
 * @param answeredSurvey.smoker
 * @param answeredSurvey.height
 * @param answeredSurvey.weight
 * @returns {*}
 */
export const httpSaveAnsweredBusinessSurvey = (answeredSurvey) => {
    const url = "/employee/answer";
    return post(url, answeredSurvey);
};
