import {post, get} from "axios";

/**
 * save the answered survey
 * @param doctorData
 * @param doctorData.first_name
 * @param doctorData.last_name
 * @param doctorData.identification_document
 * @param doctorData.province_association
 * @param doctorData.mobile_number
 * @param doctorData.birth_date
 * @param doctorData.email
 * @param doctorData.regions_id
 * @param doctorData.provinces
 * @param doctorData.is_admin

 * @returns {*}
 */
export const httpRegisterAsDoctor = (doctorData) => {
    const url = "/users/apply";
    return post(url, doctorData);
};

export const httpGetProvinces = () => {
    const url = "/provinces";
    return get(url);
};

export const httpGetRegions = () => {
    const url = "/regions";
    return get(url);
};

