import React, {Component} from 'react';
import PropTypes from "prop-types";
import Header from "../../Header/Header.component";
import Loader from "../../Shared/Loader.component";
import MultipleAnswer from "../MultipleAnswer/MultipleAnswer";
import SingleAnswer from "../SingleAnswer/SingleAnswer";
import Footer from "../../Footer/Footer.component";


class SurveyQuestionList extends Component {
	static propTypes = {
		isLoading: PropTypes.bool.isRequired,
		survey: PropTypes.any.isRequired,
		node: PropTypes.any.isRequired,
		currentNode: PropTypes.any.isRequired,
		goBack: PropTypes.func.isRequired,
		toggleSignAnswers: PropTypes.func.isRequired,
		onSelect: PropTypes.func.isRequired,
		surveyId: PropTypes.any.isRequired,
		notExist: PropTypes.any.isRequired

	};

	render() {
		const {isLoading, survey, node, currentNode, goBack,toggleSignAnswers,onSelect,surveyId,notExist} = this.props;
		return (
			<>
				<Header/>
				<section className={`survey-section`} style={{minHeight:'70vh'}}>
					<div className="container survey-container">
						{isLoading ? <Loader/> : survey && <>
							<div className={`row survey-answers`}>
								{node && <>

									<div className="col-12">
										<h3>
											{node.text}
										</h3>
									</div>
									<div className="col-12">
										{node.multiple ? <>
												<p className={`small-text`}>*puoi selezionare più di una risposta</p>
												<MultipleAnswer answers={node.nodes}
																		 toggleSignAnswers={toggleSignAnswers}
																		 currentNode={currentNode}
																		 goBack={goBack}
																		 onSelect={onSelect}/>
																		 </> :
											<SingleAnswer answers={node.nodes}
														  toggleSignAnswers={toggleSignAnswers}
														  currentNode={currentNode}
														  goBack={goBack}
														  onSelect={onSelect}/>}
									</div>
								</>}
								{/*<div className="col-12">
								{!node && <RecapAnswersClean survey={survey} surveyId={surveyId}/>}
							</div>*/}
							</div>
						</>}

						{/*{notExist && <div>
						<div className="row">
							<div className="col-12">
								<h1>Non ci sono questionari!</h1>
							</div>
						</div>
					</div>}*/}
						{/* <hr/>*/}
						{/*<ExportTemplateFlat survey={survey}/>*/}
					</div>
				</section>
				<Footer/>
			</>
		);
	}
}

export default SurveyQuestionList;
