import React, {Component} from 'react';
import Header from "../../Components/Header/Header.component";
import Footer from "../../Components/Footer/Footer.component";
import classes from './Home.module.scss';
import UserCard from "../../Components/UserCard/UserCard.component";
import doctorIll from '../../images/marcello.png';
import ciociaria from '../../images/newspaper-photos/ciociaria-oggi.png';
import corriere from '../../images/newspaper-photos/corriere.png';
import messagero from '../../images/newspaper-photos/il-messaggero.png';
import primapagina from '../../images/newspaper-photos/prima-pagina-news.png';
import rai from '../../images/newspaper-photos/rai-news.png';

class Home extends Component {
    constructor(props) {
        super(props);
    }

    state = {};

    render() {
        const {match: {params}} = this.props;
        const url = (params && params.doctor) ? `${params.doctor}` : '';
        return (
            <div className={`${classes.pageWrap}`}>
                <Header hasNav/>
                <div className={`${classes.main}`}>
                    <section className={`${classes.content}`}>
                        <div className={`container`}>
                            <div className={`row align-items-center ${classes.fullHeight}`}>
                                <div className={`col-12 col-md-12  text-center`}>
                                    <h1 className={classes.title} style={{marginBottom: '32px'}}>
                                        Ogni giorno al tuo fianco contro il Covid-19.<br/>
                                        Fai sapere come stai ai nostri medici per ricevere assistenza!
                                    </h1>
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1">
                                            <p className={`${classes.subtitle}`}>
                                                ComeStai è un questionario realizzato da medici specializzati per la
                                                valutazione dei tuoi sintomi in relazione al Covid-19. Tutte le tue
                                                risposte verranno esaminate da un medico competente a te assegnato che
                                                potrà monitorare il tuo stato di salute giorno per giorno.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 d-lg-flex d-md-block justify-content-lg-end">
                                    <UserCard ctaLink={`${url}/survey`} ctaText={"Compila il questionario >"}
                                              title={"Sei un paziente?"}
                                              icon={"user"}
                                              message={`Compila il questionario. Un medico valuterà le risposte e monitorerà il
                                              tuo stato di salute.`}
                                    />
                                </div>
                                <div className="col-md-6 col-12">
                                    <UserCard ctaLink={`${url}/doctor-apply`}
                                              icon={"doctor"}
                                              ctaText={"Registrati >"}
                                              title={"Sei un medico?"}
                                              message={`Registrati a ComeStai ed accedi alla tua area personale. Potrai
                                              monitorare le condizioni dei tuoi pazienti.`}
                                    />
                                </div>
                                <div className="col-md-6 col-12 d-lg-flex d-md-block justify-content-lg-end">
                                    <UserCard ctaLink={`${url}/business-apply`}
                                              icon={"company"}
                                              ctaText={"Contattaci >"}
                                              title={"Sei un'azienda?"}
                                              message={`Contattaci per ricevere tutti i dettagli necesari ad avere la soluzione più adatta alla tua azienda.`}
                                    />
                                </div>
                                <div className="col-md-6 col-12">
                                    <UserCard ctaLink={`${url}/business-survey/`}
                                              icon={"employee"}
                                              ctaText={"Compila il questionario >"}
                                              title={"Sei un dipendente?"}
                                              message={`Compila il questionario. Un medico valuterà le risposte e monitorerà il
                                              tuo stato di salute.`}
                                    />
                                </div>
                            </div>


                            <div className={classes.explain_section}>
                                <div className="row">
                                    <div className="col-lg-7 col-md-10 col-12">
                                        <div className={classes.explain_title}>
                                            <h2>Che cos’è ComeStai?</h2>
                                            <p>ComeStai è il nuovo sistema online ideato per i medici che permette di
                                                fornire una anamnesi dedicata al COVID-19 e consente un monitoraggio
                                                dello stato di salute.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-start">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className={classes.explain_inner}>
                                            <h4>Chi avrà accesso alle mie risposte?</h4>
                                            <p>Solo i medici registrati su ComeStai hanno accesso alle risposte e
                                                possono monitorare, costantemente e a distanza, lo stato di salute di
                                                ogni paziente.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 offset-lg-1 col-md-5 col-12">
                                        <div className={classes.explain_inner}>
                                            <h4>Ogni quanto ripetere il questionario?</h4>
                                            <p>Per un corretto monitoraggio è necessario ripetere il questionario ogni
                                                24 ore e/o all’aggravarsi dei sintomi.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5 col-md-6 col-12">
                                        <div className={classes.explain_inner}>
                                            <h4>Chi sono i medici di ComeStai?</h4>
                                            <p>Tutti i medici di famiglia, responsabili sanitari regionali o medici
                                                volontari hanno la possibilità di contribuire alla lotta contro il
                                                Covid-19 registrandosi sulla piattaforma.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-4 col-12 d-md-block d-none">
                                        <img className="img-fluid" src={doctorIll} alt={"doctor"}/>
                                    </div>
                                </div>
                            </div>

                            <div className={`row py-5 ${classes.newspaper}`}>
                                <div className={'col-12'}>
                                    <div className={`${classes.newspaperTitle} text-center`}>
                                        <h2>Parlano del progetto</h2>
                                    </div>
                                </div>

                                <div className={'col-12'}>
                                    <div className={`${classes.imageContainer}`}>
                                        <div className={`${classes.imageWrapperFloat}`}>
                                            <img src={messagero} title={''} alt={''}/>
                                        </div>
                                        <div className={`${classes.imageWrapperFloat}`}>
                                            <img src={rai} title={''} alt={''}/>
                                        </div>
                                        <div className={`${classes.imageWrapperFloat}`}>
                                            <img src={corriere} title={''} alt={''}/>
                                        </div>
                                        <div className={`${classes.imageWrapperFloat}`}>
                                            <img src={ciociaria} title={''} alt={''}/>
                                        </div>
                                        <div className={`${classes.imageWrapperFloat}`}>
                                            <img src={primapagina} title={''} alt={''}/>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className={'col-12 col-md-6 col-lg-2 offset-lg-1'}>
                                    <div className={`${classes.imageWrapper}`}>
                                        <img src={messagero} title={''} alt={''}/>
                                    </div>
                                </div>
                                <div className={'col-12 col-md-6 col-lg-2'}>
                                    <div className={`${classes.imageWrapper}`}>
                                        <img src={rai} title={''} alt={''}/>
                                    </div>
                                </div>
                                <div className={'col-12 col-md-6 col-lg-2'}>
                                    <div className={`${classes.imageWrapper}`}>
                                        <img src={corriere} title={''} alt={''}/>
                                    </div>
                                </div>
                                <div className={'col-12 col-md-6 col-lg-2'}>
                                    <div className={`${classes.imageWrapper}`}>
                                        <img src={ciociaria} title={''} alt={''}/>
                                    </div>
                                </div>
                                <div className={'col-12 col-md-6 col-lg-2 offset-md-3 offset-lg-0'}>
                                    <div className={`${classes.imageWrapper}`}>
                                        <img src={primapagina} title={''} alt={''}/>
                                    </div>
                                </div>*/}

                            </div>

                        </div>
                    </section>
                    {/*<section className={`${classes.banner}`}>
                        <div className={`container`}>
                            <div className={`row align-items-center`}>
                                <div className={`col-12 text-center`}>
                                    <h2 style={{color: '#fff', fontWeight: '800', fontSize: '32px'}}>Sei un medico di
                                        famiglia, responsabile sanitario regionale o un medico volontario?</h2>
                                    <NavLink to={`${url}/doctor-apply`} style={{color: '#fff'}}>Premi qui per richiedere
                                        un
                                        account medico con accesso ai dati relativi ai pazienti nel tuo CAP.</NavLink>
                                </div>
                            </div>
                        </div>
                    </section>*/}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Home;
