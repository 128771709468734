import React from "react";

function Logo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1920"
            height="371"
            viewBox="0 0 1920 371"
        >
            <defs>
                <path
                    id="a"
                    d="M162.454 8.392L8.237 162.609A28.026 28.026 0 000 182.386v.213a28.026 28.026 0 008.237 19.777 28.031 28.031 0 0019.883 8.236 28.034 28.034 0 0019.883-8.236L202.22 48.158c10.983-10.98 10.983-28.785 0-39.766-5.49-5.49-12.686-8.235-19.882-8.235-7.197 0-14.393 2.745-19.884 8.235"
                > </path>
                <linearGradient
                    id="b"
                    x1="3.914%"
                    x2="96.086%"
                    y1="96.085%"
                    y2="3.914%"
                >
                    <stop offset="0%" stopColor="#B84FF7"> </stop>
                    <stop offset="100%" stopColor="#8226ED"> </stop>
                </linearGradient>
                <path
                    id="d"
                    d="M217.926.939c-15.53 0-28.12 12.588-28.12 28.118 0 15.531 12.59 28.12 28.12 28.12h90.509v212.431L95.455 56.626a28.117 28.117 0 00-19.884-8.236H28.12C12.589 48.39 0 60.979 0 76.509s12.589 28.12 28.12 28.12h35.805l252.746 252.747a28.111 28.111 0 0019.89 8.236 28.118 28.118 0 0028.113-28.12V29.057c0-15.53-12.589-28.118-28.119-28.118H217.926z"
                > </path>
                <linearGradient
                    id="e"
                    x1="41.295%"
                    x2="100.995%"
                    y1="65.211%"
                    y2="5.511%"
                >
                    <stop offset="0%" stopColor="#B84FF7"> </stop>
                    <stop offset="100%" stopColor="#8226ED"> </stop>
                </linearGradient>
                <path
                    id="g"
                    d="M0.6697 0.083L213.743 0.083 213.743 246.618 0.6697 246.618z"
                > </path>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(1 1)">
                    <g transform="translate(0 155.575)">
                        <mask id="c" fill="#fff">
                            <use xlinkHref="#a"> </use>
                        </mask>
                        <path
                            fill="url(#b)"
                            d="M162.454 8.392L8.237 162.609A28.026 28.026 0 000 182.386v.213a28.026 28.026 0 008.237 19.777 28.031 28.031 0 0019.883 8.236 28.034 28.034 0 0019.883-8.236L202.22 48.158c10.983-10.98 10.983-28.785 0-39.766-5.49-5.49-12.686-8.235-19.882-8.235-7.197 0-14.393 2.745-19.884 8.235"
                            mask="url(#c)"
                        > </path>
                    </g>
                    <g transform="translate(0 .575)">
                        <mask id="f" fill="#fff">
                            <use xlinkHref="#d"> </use>
                        </mask>
                        <path
                            fill="url(#e)"
                            d="M217.926.939c-15.53 0-28.12 12.588-28.12 28.118 0 15.531 12.59 28.12 28.12 28.12h90.509v212.431L95.455 56.626a28.117 28.117 0 00-19.884-8.236H28.12C12.589 48.39 0 60.979 0 76.509s12.589 28.12 28.12 28.12h35.805l252.746 252.747a28.111 28.111 0 0019.89 8.236 28.118 28.118 0 0028.113-28.12V29.057c0-15.53-12.589-28.118-28.119-28.118H217.926z"
                            mask="url(#f)"
                        > </path>
                    </g>
                    <g transform="translate(455 .575)">
                        <path
                            fill="#1D0F41"
                            d="M126.74 223.342l99.374 136.882a.878.878 0 01-.711 1.394h-67.251a1.76 1.76 0 01-1.428-.731l-69.842-97.193-29.525 31v65.168c0 .97-.787 1.756-1.758 1.756H2.524c-.97 0-1.757-.786-1.757-1.756V7.106c0-.971.787-1.758 1.757-1.758h53.075c.97 0 1.758.787 1.758 1.758v211.808l91.5-96.394c.332-.35.793-.548 1.275-.548h72.948c.777 0 1.172.934.63 1.49l-96.97 99.88z"
                        > </path>
                        <path
                            fill="#1D0F41"
                            d="M403.783 176.7a1.752 1.752 0 01-2.004 1.73c-5.725-.79-11.408-1.185-16.696-1.185-44.287 0-64.463 25.59-64.463 70.37v112.407c0 .97-.787 1.759-1.757 1.759h-53.568c-.97 0-1.757-.788-1.757-1.759V123.891c0-.972.787-1.758 1.757-1.758h52.091c.971 0 1.757.787 1.757 1.758v36.624c11.318-26.08 37.891-41.334 69.385-41.334 5.777 0 10.861.692 13.804 1.206a1.751 1.751 0 011.451 1.73v54.584z"
                        > </path>
                        <path
                            fill="#1D0F41"
                            d="M613.9 241.55c0-49.701-31.494-75.29-66.924-75.29-34.94 0-66.924 25.589-66.924 75.29 0 49.7 31.985 76.272 66.924 76.272 35.43 0 66.924-26.08 66.924-76.272m57.574 0c0 73.812-52.654 127.45-124.498 127.45-71.353 0-124.006-53.638-124.006-127.45 0-73.321 52.653-126.96 124.006-126.96 71.844 0 124.498 53.639 124.498 126.96"
                        > </path>
                        <path
                            fill="#1D0F41"
                            d="M714.285 359.861V123.73c0-.97.787-1.757 1.757-1.757h51.107c.97 0 1.757.786 1.757 1.757v28.753c13.779-24.605 43.797-37.399 70.86-37.399 31.494 0 59.05 14.27 71.846 42.32 18.698-31.494 46.747-42.32 77.75-42.32 43.303 0 84.638 27.556 84.638 91.036v153.742c0 .971-.787 1.757-1.757 1.757h-51.6c-.97 0-1.756-.786-1.756-1.757V215.47c0-28.049-14.27-49.209-46.257-49.209-30.017 0-49.7 23.62-49.7 53.144v140.457c0 .971-.787 1.757-1.757 1.757H868.59a1.757 1.757 0 01-1.758-1.757V215.47c0-27.753-13.973-49.514-46.947-49.206-27.278.255-49.01 23.013-49.01 50.292l-.001 143.306c0 .971-.786 1.757-1.757 1.757h-53.075c-.97 0-1.757-.786-1.757-1.757"
                        > </path>
                        <path
                            fill="#1D0F41"
                            d="M1131.568 359.861V123.73c0-.97.787-1.757 1.757-1.757h53.076c.97 0 1.757.786 1.757 1.757v236.132c0 .971-.786 1.757-1.757 1.757h-53.076c-.97 0-1.757-.786-1.757-1.757M1159.617.425c20.667 0 37.399 16.732 37.399 37.4 0 20.667-16.732 36.906-37.4 36.906-20.175 0-36.906-16.239-36.906-36.907 0-20.667 16.73-37.399 36.907-37.399"
                        > </path>
                        <g transform="translate(1249 115)">
                            <mask id="h" fill="#fff">
                                <use xlinkHref="#g"> </use>
                            </mask>
                            <path
                                fill="#1D0F41"
                                d="M55.994 246.618H2.428a1.757 1.757 0 01-1.758-1.757V8.729c0-.97.787-1.757 1.758-1.757h52.09c.97 0 1.757.787 1.757 1.757v30.229C72.022 11.4 100.564.083 127.137.083c58.557 0 86.607 42.32 86.607 94.973v149.805c0 .971-.788 1.757-1.758 1.757h-53.567c-.97 0-1.757-.786-1.757-1.757V104.897c0-29.525-13.288-53.146-49.21-53.146-32.478 0-49.701 25.097-49.701 56.591v136.519c0 .971-.786 1.757-1.757 1.757"
                                mask="url(#h)"
                            > </path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Logo;
