import React, {Component} from 'react';
import classes from './ThankYou.module.scss';
import PropTypes from "prop-types";
import {Field, FieldArray, Formik} from "formik";
import CheckBox from "../../Components/Shared/CheckBox.component";
import * as Yup from "yup";
import Header from "../../Components/Header/Header.component";
import {httpGetPathologies, httpSaveAnsweredBusinessSurvey, httpSaveAnsweredSurvey} from "../../http/Survey.http";
import {flatAnswersToArray} from "../../Helpers/Utils";
import Footer from "../../Components/Footer/Footer.component";
import {withRouter} from "react-router-dom";


class ThankYou extends Component {
	static propTypes = {
		goBack: PropTypes.any,
		surveyId: PropTypes.any,
		currentStateId: PropTypes.any,
		survey: PropTypes.any,
		history: PropTypes.any
	};
	lastFormValues = localStorage.getItem("lastFormValues") ?
		JSON.parse(localStorage.getItem("lastFormValues")) : {};
	state = {
		surveyUserData: {
			first_name: '',
			last_name: '',
			fiscal_code: '',
			address: '',
			mobile_number: '',
			zip: '',
			email: '',
			smoker: 'no',
			weight: '',
			height: '',
			notes: '',
			pathologies: [],
			doctor_code: '',
			privacy: false,
			summary: '',
			...this.lastFormValues
		},
		flatAnswersArray: [],
		showAlert: false,
		single_doctor: false,
		alertMessage: '',
		showMore: false
	};

	componentDidMount() {
		const {survey} = this.props;
		this.getPathologies();

		if (survey) {
			this.setState({
				...this.state,
				flatAnswersArray: flatAnswersToArray(survey)
			});
		}
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	getPathologies = async () => {
		const {surveyUserData} = this.state;
		try {
			const {survey,match:{params:{doctor}}} = this.props;
			const {data} = await httpGetPathologies();
			this.setState({
				surveyUserData: {
					...surveyUserData,
					pathologies: data,
					doctor_code:doctor?doctor:''
				},
				single_doctor: !!doctor
			})
		} catch (e) {
			//todo: gestire eccezione grafica
		}
	};

	render() {
		const {goBack, survey,isBusiness} = this.props;
		const {surveyUserData, flatAnswersArray, showAlert, alertMessage, single_doctor,showMore} = this.state;
		const {signupSchemaHelper, handleSubmit, onChangeDoctorCode} = this;
		const YupErrors = signupSchemaHelper();

		return (
			<>
				<Header/>
				<div className={`${classes.wrapper}`}>
					<div className={`container`}>
						<div className={`row`}>
							<div className={`col-12 col-md-6 col-xl-4`}>
								<a className=" text-left" onClick={goBack}>{`<`} Indietro</a>
							</div>
						</div>
						<div className={`row my-4`}>
							<div className={`col-12 col-lg-8 offset-lg-2`}>
								<h1>Compila il form di seguito per mostrare il risultato al medico.</h1>
							</div>
							{(survey && !!flatAnswersArray.length) && <>
								<div className={`col-12 col-lg-8 offset-lg-2`}>
									<p className={`text-center`}>Ecco il risultato della tua anamnesi:</p>
									<div className={`${classes.result}`} onClick={()=>!showMore && this.setState({showMore:true})}>
										{(showMore || flatAnswersArray.length ===1) ? flatAnswersArray.map(e => <p
											className={`text-center list-formatter`}>{e.join(', ')}.</p>):
											<p className={`text-center list-formatter`} style={{cursor:'pointer'}}>{flatAnswersArray[0].join(', ')}... <b>Leggi tutto</b></p>

										}
									</div>
								</div>
							</>}
							<div className={`col-12 col-lg-8 offset-lg-2`}>
								<p className={classes.subtitle}>
									Abbiamo bisogno dei dati personali indicati di seguito in modo che il medico possa
									fornirti un’assistenza adeguata ed, eventualmente, metterti in contatto con il
									numero unico delle emergenze.
								</p>
							</div>
						</div>

						<div className={classes.formContainer}>
							<div className={`row`}>
								<div className={`col-12`}>
									<p className={classes.subtitle} style={{textAlign: 'left', fontSize: '14px'}}>
										*Campi obbligatori
									</p>
									{showAlert && <p className={`${classes.alertMessage}`}>{alertMessage}</p>}
									<Formik
										initialValues={surveyUserData}
										enableReinitialize={true}
										validationSchema={YupErrors}
										onSubmit={(values, {setSubmitting, setErrors}) => {
											handleSubmit(values, setSubmitting, setErrors);
										}}
									>
										{({
											  errors,
											  touched,
											  values,
											  handleChange,
											  setFieldValue,
											  handleSubmit,
											  resetForm,
											  submitCount,
											  isSubmitting,
											  isValidating,
											  isValid,
											  setErrors
										  }) => (
											<div>
												<form onSubmit={handleSubmit}>

													<div className="row">
														<div className="col-6">
															<div className="form-group">
																<label htmlFor={'first_name'}>Nome*</label>
																<Field name='first_name' type='text'
																	   placeholder="Mario"
																	   className={"form-control"}/>
																{errors.first_name && touched.first_name &&
																<div
																	className='invalid-input'>{errors.first_name}</div>}
															</div>
														</div>
														<div className="col-6">
															<div className="form-group">
																<label htmlFor={'last_name'}>Cognome*</label>
																<Field name='last_name' type='text'
																	   placeholder="Rossi"
																	   className={"form-control"}/>
																{errors.last_name && touched.last_name &&
																<div
																	className='invalid-input'>{errors.last_name}</div>}
															</div>
														</div>
													</div>

													<div className={'row'}>
														<div className="col-12">
															<div className="form-group">
																<label htmlFor={'fiscal_code'}>Codice
																	Fiscale*</label>
																<Field name='fiscal_code' type='text'
																	   placeholder="Codice Fiscale"
																	   className={"form-control"}/>
																{errors.fiscal_code && touched.fiscal_code &&
																<div
																	className='invalid-input'>{errors.fiscal_code}</div>}
															</div>
														</div>
													</div>

													<div className={`row`}>
														<div className="col-12">
															<div className="form-group">
																<label htmlFor={'email'}>Email*</label>
																<Field name='email' type='email'
																	   placeholder="tuaemail@example.com"
																	   className={"form-control"}/>
																{/*<ErrorMessage name="email" />*/}
																{errors.email && touched.email &&
																<div className='invalid-input'>{errors.email}</div>}
															</div>
														</div>
													</div>


													<div className="row">
														<div className="col-12">
															<div className="form-group">
																<label htmlFor={'mobile_number'}>
																	Recapito telefonico*</label>
																<Field name='mobile_number' type='text'
																	   placeholder="Inserisci un numero raggiungibile"
																	   className={"form-control"}/>
																{errors.mobile_number && touched.mobile_number &&
																<div
																	className='invalid-input'>{errors.mobile_number}</div>}
															</div>
														</div>
													</div>

													<div className={'row'}>
														<div className="col-12">
															<div className="form-group">
																<label htmlFor={'address'}>Indirizzo*</label>
																<Field name='address' type='text'
																	   placeholder="Inserisci l'indirizzo di residenza"
																	   className={"form-control"}/>
																{errors.address && touched.address &&
																<div
																	className='invalid-input'>{errors.address}</div>}
															</div>
														</div>
													</div>

													<div className={`row`} style={{marginBottom: '40px'}}>
														<div className="col-12">
															<div className="form-group">
																<label htmlFor={'zip'}>CAP*</label>
																<Field name='zip' type='text'
																	   placeholder="00000"
																	   className={"form-control"}/>

																{errors.zip && touched.zip &&
																<div
																	className='invalid-input'>{errors.zip}</div>}
															</div>
														</div>
													</div>

													<div className="row">
														<div className={'col-12'}>
															<h3 style={{marginBottom: '30px'}}>Seleziona eventuali
																patologie concomitanti</h3>
														</div>

														<div className={"col-12"}>
															<div style={{marginBottom: '40px'}}
																 className={'form-group'}>

																<FieldArray
																	name="pathologies"
																	render={arrayHelpers => (
																		<div className={'row'}>

																			{values.pathologies.map((disease, index) => (
																				<div key={index}
																					 className={'col-md-6 formCustomCheckmark'}>
																					<Field
																						id={`pathologies.${index}.status`}
																						name={`pathologies.${index}.status`}
																						component={CheckBox}
																						label={disease.name}
																						onChange={() => {
																							if (disease.status) {
																								setFieldValue(`pathologies.${index}.status`, false)
																							} else {
																								setFieldValue(`pathologies.${index}.status`, true)
																							}
																						}}
																					/>
																				</div>
																			))}
																		</div>
																	)}
																/>
															</div>

														</div>
													</div>
													<div className="row">
														<div className={"col-12"} style={{marginBottom: '40px'}}>
															<h3 style={{marginBottom: '30px'}}>Sei un
																fumatore?*</h3>
															<Field component="div" name={'smoker'}>
																<input
																	type="radio"
																	id="smoker"
																	name="smoker"
																	value="si"
																	checked={values.smoker === "si"}
																	onChange={event => {
																		setFieldValue("smoker", "si");
																	}}
																/>
																<label htmlFor={`smoker`}>Si</label>

																<input
																	type="radio"
																	id="smoker"
																	name="smoker"
																	value="no"
																	checked={values.smoker === "no"}
																	onChange={event => {
																		setFieldValue("smoker", "no");
																	}}
																/>
																<label htmlFor="`smoker`">No</label>
															</Field>
														</div>
													</div>

													<div className={`row`}>
														<div className="col-12" style={{marginBottom: '40px'}}>
															<h3 style={{marginBottom: '30px'}}>Peso e altezza</h3>
															<div className={`form-row`}>
																<div className="form-group col-md-2">
																	<label htmlFor={'weight'}>Peso*</label>
																	<Field name='weight' type='text'
																		   placeholder="Kg"
																		   className={"form-control"}/>
																	{errors.weight && touched.weight &&
																	<div
																		className='invalid-input'>{errors.weight}</div>}
																</div>
																<div className="form-group col-md-2">
																	<label htmlFor={'height'}>Altezza*</label>
																	<Field name='height' type='text'
																		   placeholder="Centimetri"
																		   className={"form-control"}/>
																	{errors.height && touched.height &&
																	<div
																		className='invalid-input'>{errors.height}</div>}
																</div>
															</div>
														</div>
													</div>

													<div className={'row'}>
														<div className="col-12">
															<div className="form-group">
																<Field name='notes' as='textarea'
																	   placeholder="Note per il medico"
																	   className={`form-control ${classes.notesField}`}/>
																{errors.notes && touched.notes &&
																<div
																	className='invalid-input'>{errors.notes}</div>}
															</div>
														</div>
													</div>
													{!isBusiness && <div className={'row'}>
														<div className="col-12">
															<div className="form-group formCustomCheckmark"
																 style={{margin: '16px 0'}}>
																<input type="checkbox" name={'doctor_code'}
																	   id={'doctor_code'} checked={single_doctor}
																	   disabled={single_doctor && !!surveyUserData.doctor_code}
																	   onChange={onChangeDoctorCode(setFieldValue)}/>
																<label htmlFor="doctor_code">Ho un <b>codice
																	medico</b> fornitomi dal mio medico appositamente
																	per comestai.org
																	<small>(opzionale)</small></label>
															</div>
															{single_doctor ? <div className="form-group">
																<label htmlFor={'doctor_code'}>Codice personale fornito
																	dal medico</label>
																<Field name='doctor_code' type='text'
																	   placeholder="0000000"
																	   className={"form-control"} disabled={single_doctor && !!surveyUserData.doctor_code}/>
																{errors.doctor_code && touched.doctor_code &&
																<div
																	className='invalid-input'>{errors.doctor_code}</div>}
															</div> : null}
														</div>
													</div>}
													<div className="row py-3">
														<div className={"col-12"}>
															<div className="form-group formCustomCheckmark">
																<Field
																	id={`privacy`}
																	name={`privacy`}
																	component={CheckBox}
																	isLink={true}
																	url={'/privacy'}
																	label={`Accetto e acconsento l’informativa sulla privacy nonchè al trattamento dei dati sensibili`}
																/>
																{errors.privacy && touched.privacy &&
																<div
																	className='invalid-input'>{errors.privacy}</div>}
															</div>
														</div>
													</div>

													<div className={"row"}>
														<div className={"col-12 text-center"}>
															<div className="form-group">
																<button className={`btn ${classes.btnDefault}`}
																		type="submit"
																>
																	INVIA
																</button>
															</div>
															{(Object.keys(errors).length && submitCount > 0) ? <div
																className='invalid-input'>Sono presenti errori nel
																form</div> : null}
														</div>
													</div>
												</form>
											</div>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</>

		)
	}

	signupSchemaHelper = () => {
		const {single_doctor} = this.state;
		return Yup.object().shape({
			first_name: Yup.string()
				.required('Nome richiesto.'),
			last_name: Yup.string()
				.required('Cognome richiesto.'),
			address: Yup.string()
				.required('Indirizzo richiesto.'),
			fiscal_code: Yup.string().length(16, 'Il Codice Fiscale deve essere di 16 cifre.')
				.required('Codice Fiscale richiesto.'),
			mobile_number: Yup.string()
				.required('Numero di telefono richiesto'),
			email: Yup.string()
				.email('Email non valida.')
				.required('Email richiesta.'),
			zip: Yup.string().length(5, 'Il CAP deve essere di 5 cifre.').required('CAP richiesto.'),
			weight: Yup.string()
				.required('Peso richiesto.'),
			height: Yup.string()
				.required('Altezza richiesta.'),
			privacy: Yup.boolean()
				.oneOf([true], 'Per continuare devi accettare la privacy'),
			notes: Yup.string(),
			doctor_code: single_doctor ? Yup.string().required('Hai richiesto di assegnare il tuo questionario ad un medico specifico, ti preghiamo di inserire il codice del medico o di togliere la selezione al "codice medico"') : Yup.string()
		})
	};

	onChangeDoctorCode = (setFieldValue) => (event) => {
		const target = event.target;
		const value = target.checked;
		setFieldValue('doctor_code', '');
		this.setState({
			single_doctor: value
		})
	};

	handleSubmit = async (values, setSubmitting, setErrors) => {
		setSubmitting(true);
		const {surveyId, currentStateId, survey,match:{params:{doctor}},isBusiness} = this.props;
		const {flatAnswersArray} = this.state;
		let formValues = {...values};
		const url = doctor ? `/${doctor}/torna-domani`:'/torna-domani'
		formValues.pathologies = values.pathologies.filter(e => e.status).map(e => e.id);
		if (flatAnswersArray && flatAnswersArray.length > 0) {
			formValues.summary = flatAnswersArray.map(e => e.join(', ')).reduce((a, b) => a + b + '\n')
		}
		let answeredSurvey = {
			survey_id: surveyId,
			patient_status_id: currentStateId,
			json: survey,
			...formValues
		};

		try {
			if(isBusiness){
				const {data: response} = await httpSaveAnsweredBusinessSurvey(answeredSurvey)
			}else{
				const {data: response} = await httpSaveAnsweredSurvey(answeredSurvey);
			}
			setSubmitting(false);

			let localStorageValues = {...values};
			delete localStorageValues.notes;
			delete localStorageValues.privacy;
			delete localStorageValues.summary;
			localStorage.setItem('lastFormValues', JSON.stringify(localStorageValues));
			this.lastFormValues = localStorageValues;

			this.setState({
				showAlert: false,
			}, () => {
				this.props.history.push(url)
			});
		} catch (error) {
			console.log(error.response.data.message);
			let errors = {};
			for (const key in error.response.data.error) {
				if (error.response.data.error[key]) {
					errors[key] = error.response.data.error[key][0];
				}
			}
			this.setState({
				showAlert: true,
				alertMessage: error.response.data.message ? error.response.data.message : 'Ci sono stati degli errori, ricontrolla i dati inseriti e ritenta l\'invio.',
			});
			window.scrollTo(0, 0);
			//console.log(Object.keys(errors).length);
			setErrors(errors);
			setSubmitting(false);
		}
	};

	closeAlert = () => {
		this.setState({
			showAlert: false,
		})
	}
}

export default withRouter(ThankYou);
