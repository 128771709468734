import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import * as axios from "axios";
Sentry.init({
    dsn: "https://c63e62057f224f489ea241b0b64b1fdc@sentry.io/5178492",
    environment: process.env.REACT_APP_APP_ENVIRONMENT ? process.env.REACT_APP_APP_ENVIRONMENT : 'development'
});

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
axios.defaults.withCredentials = true;

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
