import React, {Component} from 'react';
import classes from './HealedPatient.module.scss';
import Header from "../../Components/Header/Header.component";
import Footer from "../../Components/Footer/Footer.component";
import {Link} from "react-router-dom";
import logo from "../../images/logo-comestai-150px-bianco.png";
import {httpSetEmployeeHealed} from "../../http/Patient.http";

class HealedEmployee extends Component {

    state = {
        healedCorrectly: false,
        user: 'Paziente',
        copied: ''
    };

    componentDidMount() {
        const {match:{params:{token}}} = this.props;
        this.sendPrayToApi(token)
    }


    render() {
        const {healedCorrectly, user, copied} = this.state;
        return (
            <>
                <div className={`${classes.pageWrap}`}>
                    <Header/>
                    <div className={`${classes.wrapper}`}>
                        <div className={`container`}>
                            <div className={`row`}>
                                <div
                                    className={`col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center`}>
                                    <Link to={`/home`} className={`${classes.logoBig}`}>
                                        <img src={logo}/>
                                    </Link>
                                </div>

                                {healedCorrectly ?
                                    <>
                                        <div
                                            className={`col-12 col-md-8 offset-md-2 pt-5 text-center ${classes.communication} `}>
                                            <h2 className={`${classes.communicationTitle}`}>
                                                Gentile {user},<br/>
                                                grazie per averci comunicato di essere guarito.
                                                <br/>
                                            </h2>
                                            <div className={classes.boxMessage}>
                                                <p className={`${classes.communicationParagraph}`}>Il medico ha ricevuto correttamente
                                                    il suo messaggio e resta a sua completa disposizione in caso di
                                                    necessità.</p>
                                                <p className={`${classes.communicationParagraph}`}>
                                                    Le ricordiamo che può consigliare l’utilizzo dell’app inviando questo
                                                    link a chi ritiene di averne bisogno:
                                                </p>
                                                <div className={classes.copyArea}>
                                                    <div className={classes.copyGroup}>
                                                        <input className={`form-control ${classes.copyInput}`}
                                                               ref={(textarea) => this.textArea = textarea}
                                                               value='www.comestai.org'
                                                        />
                                                        <button className={`btn ${classes.btnDefault}`} onClick={this.copyToClipboard}>Copia</button>
                                                    </div>
                                                    <div className={`${classes.copyMessage}`}>{copied}</div>
                                                </div>

                                                <p className={`${classes.communicationParagraph}`}>
                                                    Tutto il team di ComeStai le augura il meglio.<br/>
                                                    Un saluto.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div
                                            className={`col-12 col-md-8 offset-md-2 pt-5 text-center ${classes.communication} `}>
                                            <div className={classes.dangerIcon}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="80px"
                                                    height="80px"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <path d="M277.332 384c0 11.781-9.55 21.332-21.332 21.332s-21.332-9.55-21.332-21.332 9.55-21.332 21.332-21.332 21.332 9.55 21.332 21.332zm0 0M256 320c-8.832 0-16-7.168-16-16V122.668c0-8.832 7.168-16 16-16s16 7.168 16 16V304c0 8.832-7.168 16-16 16zm0 0"> </path>
                                                    <path d="M256 512C114.836 512 0 397.164 0 256S114.836 0 256 0s256 114.836 256 256-114.836 256-256 256zm0-480C132.48 32 32 132.48 32 256s100.48 224 224 224 224-100.48 224-224S379.52 32 256 32zm0 0"> </path>
                                                </svg>
                                            </div>
                                            <h2 className={`${classes.communicationTitle}`}>
                                                Gentile {user},<br/>
                                                purtroppo c’è stato un errore durante l’invio della sua email.
                                                <br/>
                                            </h2>
                                            <div className={classes.boxMessage}>
                                                <p className={`${classes.communicationParagraph}`}>Per essere sicuri che il medico
                                                    riceva la sua comunicazione, la invitiamo ad inviare una mail a <a
                                                        href={`mailto:info@comestai.org`}>info@comestai.org</a> con tutti i dettagli del caso.</p>
                                                <p className={`${classes.communicationParagraph}`}>
                                                    Sarà nostra cura assicurarci che il messaggio arrivi correttamente a destinazione.<br/>
                                                    La ringraziamo per l’attenzione.<br/>
                                                    Un saluto.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
        )
    }


    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({
            copied: 'Copiato!'
        });
    };

    sendPrayToApi = async (token) => {
        try {
            const {data: response} = await httpSetEmployeeHealed(token);
            const fullName = `${response.first_name} ${response.last_name}`;
            this.setState({
                healedCorrectly: true,
                user: fullName
            });
            window.scrollTo(0, 0);
        } catch (error) {
            this.setState({
                healedCorrectly: false,
            });
            window.scrollTo(0, 0);
        }
    };
}


export default HealedEmployee;
