export function traverse(x, id) {
	if (isArray(x, id)) {
		traverseArray(x, id)
	} else if ((typeof x === 'object') && (x !== null)) {
		if (x.id == id) {
			//console.log(`Imposto ${x.text} a ${!x.selected}`)
			x.selected = !x.selected
		}else{
			traverseObject(x, id)
		}
	}
	return x
}

export function traverseArray(arr, id) {
	arr.forEach((x) => {
		traverse(x, id)
	})
}


export function traverseObject(obj, id) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			traverse(obj[key], id)
		}
	}
}

export function isArray(o) {
	return Object.prototype.toString.call(o) === '[object Array]'
}

