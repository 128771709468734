import React from "react";

const CheckBox = ({
                      field: {name, value, onChange, onBlur},
                      form: {errors, touched, setFieldValue},
                      id,
                      label,
                      disabled=false,
                      className,
                      isLink,
                      url,
                      ...props
                  }) => {
    return (
        <>
            <input
                name={name}
                id={id}
                type="checkbox"
                value={value}
                checked={value}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                className="form-check-input"
            />
            {isLink ?
                <label htmlFor={id} className={`form-check-label`}><a href={url} target={'_blank'}>{label}</a></label>
                :
                <label htmlFor={id} className={`form-check-label`}>{label}</label>
            }
        </>
    );
};

export default CheckBox;
