import React, {Component} from 'react';
import classes from './CurrentState.module.scss';
import InfoBlock from '../../Components/InfoBlock/InfoBlock.component'
import PropTypes from "prop-types";
import {httpGetPatientsStatuses} from "../../http/Survey.http";
import Header from "../../Components/Header/Header.component";
import Footer from "../../Components/Footer/Footer.component";

class CurrentState extends Component {
    static propTypes = {
        saveCurrentStateId: PropTypes.func,
        goToStep: PropTypes.func,
        currentStateId: PropTypes.number,
        jumpSurvey: PropTypes.func,
    };
    state = {
        currentStates: []
    };

    componentDidMount() {
        this.getCurrentStates();
        window.scrollTo(0, 0);
    }

    getCurrentStates = async () => {
        try {
            const {data} = await httpGetPatientsStatuses();
            this.setState({
                currentStates: data
            })
        } catch (e) {
            //todo: gestire errore
        }
    }

    render() {
        const {saveCurrentStateId, currentStateId, startAgain, isBusiness, jumpSurvey} = this.props;
        const {currentStates} = this.state;
        return (
            <>
                <div className={`${classes.pageWrap}`}>
                    <Header/>
                    <div className={`${classes.wrapper}`}>
                        <div className={`container`}>
                            <div className={`row py-4`}>
                                <div className={`col-12 col-md-6 col-xl-4`}>
                                    <a className=" text-left" onClick={startAgain}>{`<`} Indietro</a>
                                </div>
                            </div>
                            <div className={`row`}>
                                <div className={`col-12 col-md-6 col-xl-4`}>
                                    {isBusiness && <InfoBlock id={0} key={0} value={0} title={'Non ho notato alcun sintomo'}
                                                              isBusiness={isBusiness}
                                                              description={''}
                                                              selected={currentStateId === 0}
                                                              onChangeHandler={() => jumpSurvey(0)}/>}
                                </div>
                                {currentStates.map(({title, description, id}, i) => (
                                    <div className={`col-12 col-md-6 col-xl-4`} key={i}>
                                        <InfoBlock id={id} key={i} value={id} title={title} description={description}
                                                   selected={currentStateId === id}
                                                   onChangeHandler={(id) => saveCurrentStateId(id)}/>
                                    </div>
                                ))}
                            </div>
                           {/* <hr/>*/}
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
        )
    }

    getHealthState = () => {
        return [
            {
                id: 1,
                title: 'Non ho sintomi e non penso di avere il Covid-19',
                description: '',
            },
            {
                id: 2,
                title: 'Il mio medico pensa che io abbia il Covid-19',
                description: 'Ipotesi di diagnosi senza la verifica del tampone.',
            },
            {
                id: 3,
                title: 'Non ho sintomi e non penso di avere il Covid-19',
                description: 'Ipotesi di diagnosi senza la verifica del tampone.',
            },
            {
                id: 5,
                title: 'Non ho sintomi e non penso di avere il Covid-19',
                description: '',
            },
            {
                id: 6,
                title: 'Il mio medico pensa che io abbia il Covid-19',
                description: 'Ipotesi di diagnosi senza la verifica del tampone.',
            },
            {
                id: 7,
                title: 'Non ho sintomi e non penso di avere il Covid-19',
                description: 'Ipotesi di diagnosi senza la verifica del tampone.',
            }
        ];
    }


}


export default CurrentState;
